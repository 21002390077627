import React, { useState, useRef, useEffect, SyntheticEvent, useCallback } from 'react';
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  createFilterOptions,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import {
  Autocomplete as MUIAutocomplete,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  Tooltip,
  AvatarGroup,
  Popover,
  Button,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import { CustomAvatar } from 'components/index';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useAppState } from 'store/Provider';
import { useSelector } from 'store/hooks';
import HelpMenuApiService from 'api/HelpMenuApiService';
// import Role from 'types/entities/VideoRole';
import Role from 'types/entities/Role';
import { noop } from 'lodash'; // Import noop from lodash

type MultiSelectUserGuideProps = {
  items: Role[];
  onChange: (categories: Role[]) => void;
  value: Role[];
  sx?: any;
  selectAllLabel: string;
  variant?: string;
  onRoleAdded: (newRole: Role) => void;
  onRoleArchived: (updatedRole: Role) => void;
  availableCategories?: Role[];
  onRoleDeletionAttempt?: () => void;
  maximumVisible: number;
};

const MultiSelectUserGuide = (props: MultiSelectUserGuideProps) => {
  const {
    items,
    onChange,
    value,
    sx,
    selectAllLabel,
    variant,
    onRoleAdded,
    onRoleArchived,
    availableCategories,
    onRoleDeletionAttempt = noop,
    maximumVisible,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const { dispatch } = useAppState();
  const maxVisible = maximumVisible; // Adjust based on your max setting
  const [dynamicItems, setDynamicItems] = useState<Role[]>(items);
  const [selectedOptions, setSelectedOptions] = useState<Role[]>(value);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [key, setKey] = useState<number>(0); // Key to force re-render
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addPopoverOpen, setAddPopoverOpen] = useState(false);
  const customer = useSelector((state) => state.app.customer);

  useEffect(() => {
    setDynamicItems(items);
  }, [items]);

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  useEffect(() => {
    if (addPopoverOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [addPopoverOpen]);

  const filterOptions = createFilterOptions<Role>({
    matchFrom: 'start',
    stringify: (option: Role) => option.name,
  });

  const handleOptionChange = useCallback(
    (event: SyntheticEvent<Element, Event>, newValue: Role[], reason: AutocompleteChangeReason) => {
      const uncheckedRole = selectedOptions.find((role) => !newValue.some((newRole) => newRole.id === role.id));
      if (uncheckedRole) {
        if (availableCategories?.some((role) => role.id === uncheckedRole.id)) {
          onRoleDeletionAttempt();
          return;
        }
      }
      setSelectedOptions(newValue);
      // onChange(newValue);
    },
    [selectedOptions, availableCategories, onRoleDeletionAttempt]
  );

  const optionRenderer = (props: any, option: Role, state: { selected: boolean }) => (
    <Grid container key={option.name}>
      <Grid item xs={12}>
        <FormControlLabel control={<Checkbox {...props} checked={state.selected} />} label={option.name} />
      </Grid>
    </Grid>
  );

  const getOptionLabel = (option: Role): string => option.name;

  const isOptionEqualToValue = (option: Role, val: Role): boolean => option.id === val.id;

  const visibleItems = selectedOptions.slice(0, maxVisible);
  const overflowItems = selectedOptions.slice(maxVisible);

  const handleAvatarGroupClick = (event: any) => {
    if (selectedOptions.length > maxVisible) {
      setPopoverOpen(true);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  const handleAutocompleteKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft') {
      event.stopPropagation();
    }
  };

  const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => (
    <Box position="relative" width="100%">
      <TextField
        {...params}
        label={selectedOptions.length > 0 ? `${selectedOptions.length} selected` : selectAllLabel}
        variant={variant as any}
        required
        onKeyDown={handleAutocompleteKeyDown}
        InputProps={{
          ...params.InputProps,
        }}
      />
    </Box>
  );
  const handleDelete = (roleId: number) => {
    let shouldArchiveRole = true;

    setSelectedOptions((prevOptions) => {
      const roleToDelete = prevOptions.find((role) => role.id === roleId);

      if (roleToDelete) {
        if (availableCategories?.some((role) => role.id === roleToDelete.id)) {
          onRoleDeletionAttempt();
          shouldArchiveRole = false;
          return prevOptions;
        }
        return prevOptions.filter((role) => role.id !== roleId);
      }

      return prevOptions;
    });

    if (shouldArchiveRole) {
      archiveRole(roleId);
    }
  };

  const archiveRole = (roleId: number) => {
    const roleToArchive = selectedOptions.find((role) => role.id === roleId);
    if (roleToArchive) {
      const roleItem: Role = {
        id: roleToArchive.id,
        name: roleToArchive.name,
        is_archived: false,
      };
      onRoleArchived(roleItem);
    }
  };

  useEffect(() => {
    const overflowItems = selectedOptions.length - maxVisible;
    if (overflowItems <= 0 && popoverOpen) {
      setPopoverOpen(false);
    }
  }, [selectedOptions, popoverOpen, maxVisible]);

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);

  const avatarsToRender: any = [];

  visibleItems.forEach((option, index) => {
    avatarsToRender.push(
      <CustomAvatar
        displayAvatar={false}
        sx={{ marginRight: '10px', padding: '8px 1px' }}
        key={option.id}
        name={option.name}
        onClose={() => handleDelete(option.id)}
      />
    );

    if (index === maxVisible - 1 && selectedOptions.length > maxVisible) {
      const remainingCount = selectedOptions.length - maxVisible;
      avatarsToRender.push(
        <Box
          component="span"
          onClick={handleAvatarGroupClick}
          sx={{
            cursor: 'pointer',
            backgroundColor: '#808080',
            borderRadius: '50%',
            padding: '2px',
            display: 'inline-flex',
            color: 'white',
            width: '24px',
            height: '24px',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '12px',
            fontSize: '12px',
          }}
          key="overflow"
        >
          +{remainingCount}
        </Box>
      );
    }
  });

  return (
    <>
      <MUIAutocomplete
        disableCloseOnSelect
        key={key}
        sx={{
          maxHeight: '150px',
          '& .Mui-focused, & .MuiAutocomplete-inputFocused': {
            backgroundColor: 'transparent',
            outline: 'none',
          },
          ...sx,
        }}
        multiple
        size="small"
        options={dynamicItems}
        value={selectedOptions}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        filterOptions={filterOptions}
        onChange={handleOptionChange}
        renderOption={optionRenderer}
        renderInput={renderInput}
        renderTags={() => null}
        ListboxProps={{
          style: {
            maxHeight: '320px',
            overflow: 'auto',
          },
        }}
        clearIcon={null}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Tooltip placement="bottom-end" title="View More Users">
          <AvatarGroup
            max={maxVisible + 1}
            sx={{ '& .MuiAvatar-root': { width: 30, height: 30, marginRight: 0.5 } }}
            onClick={handleAvatarGroupClick}
          >
            {avatarsToRender}
          </AvatarGroup>
        </Tooltip>

        <Popover
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          PaperProps={{
            style: {
              minWidth: 100,
              maxWidth: 300,
              minHeight: 100,
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
              backgroundColor: 'transparent',
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              maxHeight: 200,
              minWidth: 100,
              maxWidth: 300,
              overflowY: 'auto',
              p: 2,
            }}
          >
            {overflowItems.map((option) => (
              <CustomAvatar
                displayAvatar={false}
                key={option.id}
                name={option.name}
                onClose={() => handleDelete(option.id)}
                sx={{ marginBottom: 1 }}
              />
            ))}
          </Box>
        </Popover>
      </Box>
    </>
  );
};

export default MultiSelectUserGuide;
