import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { selectActiveGuides, selectSelectedGuides } from 'store/selectors/HelpMenuSelectors';
import useHelpMenuService from '../hooks/useHelpMenuService';

interface HelpMenuGuideDialogDeleteProps {
  getHelpMenuList: () => Promise<void>; // Adjust type if necessary
}

const HelpMenuGuideDialogDelete: React.FC<HelpMenuGuideDialogDeleteProps> = ({ getHelpMenuList }) => {
  const [t] = useTranslation();
  const { deleteGuide } = useHelpMenuService();

  const { dispatch } = useAppState();
  const selectSelectedGuide = useSelector(selectSelectedGuides);
  const activeGuide = useSelector(selectActiveGuides);
  const guides = activeGuide ? [activeGuide] : selectSelectedGuide;
  const dialogs = useSelector((state) => state.dialogs);
  const close = () => {
    dispatch(closeDialogAction('guideDelete'));
  };
  const remove = async () => {
    const guideIds = guides?.map((guide) => guide.id);
    const deletedData = await deleteGuide(guideIds[0]);
    if (deletedData) {
      getHelpMenuList();
    }
  };

  return (
    <>
      <Dialog style={{ maxHeight: 435 }} maxWidth="xs" open={dialogs.guideDelete || false}>
        <DialogTitle>{t('Delete Guides')}</DialogTitle>
        <DialogContent>
          <div>{t('Confirm_guide_videos')}</div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={close}>
            {t('Cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={remove}>
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HelpMenuGuideDialogDelete;
