import { GridSelectionModel } from '@mui/x-data-grid-pro';
import { filter, find, head } from 'lodash';
import HelpMenuListVideos from 'types/entities/HelpMenuVideos';
import HelpMenuUserGuide from 'types/entities/HelpMenuUserGuide';
import { RootState } from '../redux';

export const selectSelectedVideos = (state: RootState) =>
  getVideos(state.helpMenu?.datas ?? [], state.helpMenu.selectedVideoModel);

export const selectActiveVideos = (state: RootState) =>
  head(getVideos(state.helpMenu?.datas ?? [], state.helpMenu.activeVideoModel));

const getVideos = (videos: HelpMenuListVideos[], ids: number[] | GridSelectionModel) =>
  filter(
    ids.map((id) => find(videos, ['id', id])),
    (video): video is HelpMenuListVideos => !!video
  );

export const selectSelectedGuides = (state: RootState) =>
  getGuides(state.helpMenu?.guideDatas ?? [], state.helpMenu.selectedGuideModel);

export const selectActiveGuides = (state: RootState) =>
  head(getGuides(state.helpMenu?.guideDatas ?? [], state.helpMenu.activeGuideModel));

const getGuides = (guides: HelpMenuUserGuide[], ids: number[] | GridSelectionModel) =>
  filter(
    ids.map((id) => find(guides, ['id', id])),
    (guide): guide is HelpMenuUserGuide => !!guide
  );
