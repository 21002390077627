import ResourceAPI, { Resource, SearchParams } from 'api/ResourceAPI';
import Catalog from 'types/entities/Catalog';

type CatalogKeys = keyof Catalog;

export default class CatalogApiService {
  public static instance: CatalogApiService;

  public static baseUrl = 'catalogs' as Resource;

  public static baseUrlImport = 'catalogs/import' as Resource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return CatalogApiService.instance || (CatalogApiService.instance = new CatalogApiService());
  }

  public static async create(data: any) {
    try {
      const res = await ResourceAPI.post(this.baseUrl, data);
      return res.data.catalog;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async fetchList(props: CatalogKeys[], isArchived = false, size = '-1') {
    try {
      const res = await ResourceAPI.get('catalogs', {
        is_archived: Number(isArchived),
        size,
        attr: props.join('.'),
      });
      return res.data.datas;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async fetchById(id: number, params?: SearchParams) {
    try {
      const { data } = await ResourceAPI.getById(this.baseUrl, id, undefined, params);
      return data.catalog;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async update(id: number, data: any, additionalPath?: string) {
    try {
      const res = await ResourceAPI.put(this.baseUrl, id, data, additionalPath);
      return res.data.catalog;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async duplicate(id: number) {
    try {
      const { data } = await ResourceAPI.post(this.baseUrl, {}, id, 'duplicate');
      return data.catalog;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async delete(id: number, action?: 'delete' | 'archive') {
    try {
      //  const url = `${this.baseUrl}/${id}${action ? `?action=${action}` : ''}`;
      const url = `${this.baseUrl}/${id}${action ? `?action=${action}` : ''}` as Resource;
      console.log('url: ', url);
      const res = await ResourceAPI.delete(this.baseUrl, id, action);
      console.log('res : ', res);
      return res.data.catalog;
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        console.error('Error Message from API:', error.response.data.message);
        throw new Error(error.response.data.message);
      }
      // Default error handling
      throw new Error('An unexpected error occurred during the delete operation.');
    }
  }

  public static async deleteComplexity(catalogId: number, complexityId: number) {
    try {
      const res = await ResourceAPI.deleteWithAdditionalPath(this.baseUrl, catalogId, `complexities/${complexityId}`);
      return res.data.complexity;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async createComplexity(catalogId: number, data: any) {
    try {
      const res = await ResourceAPI.post(this.baseUrl, data, catalogId, 'complexities');
      return res.data.complexity;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async getCatalogComplexities(catalogId: number) {
    try {
      const res = await ResourceAPI.getById(this.baseUrl, catalogId, 'complexities');
      return res.data.datas;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async updateComplexity(catalogId: number, complexityId: number, data: any) {
    try {
      const res = await ResourceAPI.patchWithAdditionalPath(
        this.baseUrl,
        catalogId,
        `complexities/${complexityId}`,
        data
      );
      return res.data.complexity;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async importCatalog(body: any) {
    try {
      const {
        data: { created_catalog },
      } = await ResourceAPI.post(this.baseUrlImport, body);
      return created_catalog;
    } catch (error: unknown) {
      return null;
    }
  }
}
