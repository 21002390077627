import { Typography, Link } from '@mui/material';
import React, { useCallback } from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import HelpMenuUserGuide from 'types/entities/HelpMenuUserGuide';
import useHelpMenuService from 'features/HelpMenu/hooks/useHelpMenuService';

const FileCell = React.memo((row: HelpMenuUserGuide) => {
  const { getGuidePdf } = useHelpMenuService();

  const getGuide = useCallback(async (id) => {
    const data = await getGuidePdf(id);
    if (data?.length > 0) {
      window.open(data, '_blank');
    }
  }, []);

  return (
    <Typography noWrap variant="body2">
      <Link variant="body2" onClick={() => getGuide(row?.id)} noWrap underline="none" component="button">
        <PictureAsPdfIcon />
      </Link>
    </Typography>
  );
});

export default FileCell;
