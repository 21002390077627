import { appActions } from './actions/appActions';
import { catalogToOrderActions } from './actions/catalogToOrderActions';
import { deliverableActions } from './actions/deliverableActions';
import { deliverableSheetActions } from './actions/deliverableSheetActions';
import { dialogsActions } from './actions/dialogsActions';
import { documentsActions } from './actions/documentsActions';
import { drawerActions } from './actions/drawerActions';
import { filterActions } from './actions/filterActions';
import { loadingsActions } from './actions/loadingsActions';
import { managementActions } from './actions/managementActions';
import { snackbarActions } from './actions/snackbarActions';
import { helpmenuActions } from './actions/helpmenuActions';
import appReducer from './reducers/appReducer';
import catalogToOrderReducer from './reducers/catalogToOrderReducer';
import deliverableReducer from './reducers/deliverableReducer';
import { deliverableSheetReducer } from './reducers/deliverableSheetReducer';
import dialogsReducer from './reducers/dialogsReducer';
import documentsReducer from './reducers/documentsReducer';
import drawerReducer from './reducers/drawerReducer';
import filterReducer from './reducers/filterReducer';
import loadingsReducer from './reducers/loadingsReducer';
import managementReducer from './reducers/managementReducer';
import snackbarReducer from './reducers/snackbarReducer';
import helpmenuReducer from './reducers/helpmenuReducer';
import { RootReducer, RootState } from './redux';
import { appState } from './states/appState';
import { catalogToOrderState } from './states/catalogToOrderState';
import { deliverableSheetState } from './states/deliverableSheetState';
import { deliverableState } from './states/deliverableState';
import { dialogsState } from './states/dialogsState';
import { documentsState } from './states/documentsState';
import { drawerState } from './states/drawerState';
import { filterState } from './states/filterState';
import { loadingsState } from './states/loadingsState';
import { managementState } from './states/managementState';
import { snackbarState } from './states/snackbarState';
import { helpmenuState } from './states/helpMenuState';

const combineReducers =
  <S = RootState>(reducers: { [K in keyof S]: RootReducer<S[K]> }): RootReducer<S> =>
  (state, action) =>
    (Object.keys(reducers) as Array<keyof S>).reduce(
      (prevState, key) => ({
        ...prevState,
        [key]: reducers[key](prevState[key], action),
      }),
      state
    );

export const rootState = {
  app: appState,
  deliverable: deliverableState,
  deliverableSheet: deliverableSheetState,
  dialogs: dialogsState,
  drawer: drawerState,
  filter: filterState,
  loadings: loadingsState,
  management: managementState,
  catalogToOrder: catalogToOrderState,
  snackbar: snackbarState,
  documents: documentsState,
  helpMenu: helpmenuState,
};

export const rootActions = {
  app: appActions,
  deliverable: deliverableActions,
  deliverableSheet: deliverableSheetActions,
  dialogs: dialogsActions,
  drawer: drawerActions,
  filter: filterActions,
  loadings: loadingsActions,
  management: managementActions,
  catalogToOrder: catalogToOrderActions,
  snackbar: snackbarActions,
  documents: documentsActions,
  helpMenu: helpmenuActions,
};

export const rootReducer = () =>
  combineReducers({
    app: appReducer,
    deliverable: deliverableReducer,
    dialogs: dialogsReducer,
    deliverableSheet: deliverableSheetReducer,
    drawer: drawerReducer,
    filter: filterReducer,
    loadings: loadingsReducer,
    management: managementReducer,
    catalogToOrder: catalogToOrderReducer,
    snackbar: snackbarReducer,
    documents: documentsReducer,
    helpMenu: helpmenuReducer,
  });
