import React from 'react';
import HelpMenuTable from '../HelpMenuTable/HelpMenuTable';
import { useSelector } from 'store/hooks';
import Loader from '../../../components/Loader';

const HelpMenuAdminVideos: React.FC = () => {
  const loadings = useSelector((state) => state.loadings);
  let content;

  if (loadings?.adminvideoList) {
    content = <Loader className="loader_container_thumbnail" />;
  } else {
    content = <HelpMenuTable />;
  }

  return content;
};

export default HelpMenuAdminVideos;
