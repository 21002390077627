import React, { useState, useRef, useEffect, SyntheticEvent, useCallback } from 'react';
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  createFilterOptions,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import {
  Autocomplete as MUIAutocomplete,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  Tooltip,
  AvatarGroup,
  Popover,
  Button,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomAvatar } from 'components/index';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useAppState } from 'store/Provider';
import { useSelector } from 'store/hooks';
import HelpMenuApiService from 'api/HelpMenuApiService';
import Category from 'types/entities/VideoCategory';
import { noop } from 'lodash'; // Import noop from lodash

type MultiSelectVideoCategoryProps = {
  items: Category[];
  onChange: (categories: Category[]) => void;
  value: Category[];
  sx?: any;
  selectAllLabel: string;
  variant?: string;
  onCategoryAdded: (newCategory: Category) => void;
  onCategoryArchived: (updatedCategory: Category) => void;
  availableCategories?: Category[];
  onCategoryDeletionAttempt?: () => void;
  maximumVisible: number;
};

const MultiSelectVideoCategory = (props: MultiSelectVideoCategoryProps) => {
  const {
    items,
    onChange,
    value,
    sx,
    selectAllLabel,
    variant,
    onCategoryAdded,
    onCategoryArchived,
    availableCategories,
    onCategoryDeletionAttempt = noop,
    maximumVisible,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const { dispatch } = useAppState();
  const [t] = useTranslation();
  const maxVisible = maximumVisible; // Adjust based on your max setting
  const [dynamicItems, setDynamicItems] = useState<Category[]>(items);
  const [selectedOptions, setSelectedOptions] = useState<Category[]>(value);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [key, setKey] = useState<number>(0); // Key to force re-render
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addPopoverOpen, setAddPopoverOpen] = useState(false);
  const customer = useSelector((state) => state.app.customer);

  useEffect(() => {
    setDynamicItems(items);
  }, [items]);

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const handleAddClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setAddPopoverOpen(true);
    if (inputRef.current) {
      inputRef.current.value = '';
      setInputValue('');
    }
  }, []);

  const handleAddClose = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setInputValue('');
    }
    setAddPopoverOpen(false);
  }, []);

  useEffect(() => {
    if (addPopoverOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [addPopoverOpen]);

  const filterOptions = createFilterOptions<Category>({
    matchFrom: 'start',
    stringify: (option: Category) => option.name,
  });

  const handleOptionChange = useCallback(
    (event: SyntheticEvent<Element, Event>, newValue: Category[], reason: AutocompleteChangeReason) => {
      const uncheckedCategory = selectedOptions.find(
        (category) => !newValue.some((newCategory) => newCategory.id === category.id)
      );
      if (uncheckedCategory) {
        if (availableCategories?.some((category) => category.id === uncheckedCategory.id)) {
          onCategoryDeletionAttempt();
          return;
        }
      }
      setSelectedOptions(newValue);
      // onChange(newValue);
    },
    [selectedOptions, availableCategories, onCategoryDeletionAttempt]
  );

  const optionRenderer = (props: any, option: Category, state: { selected: boolean }) => (
    <Grid container key={option.name}>
      <Grid item xs={12}>
        <FormControlLabel control={<Checkbox {...props} checked={state.selected} />} label={option.name} />
      </Grid>
    </Grid>
  );

  const getOptionLabel = (option: Category): string => option.name;

  const isOptionEqualToValue = (option: Category, val: Category): boolean => option.id === val.id;

  const visibleItems = selectedOptions.slice(0, maxVisible);
  const overflowItems = selectedOptions.slice(maxVisible);

  const handleAvatarGroupClick = (event: any) => {
    if (selectedOptions.length > maxVisible) {
      setPopoverOpen(true);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleAddNewCategory = async () => {
    const newLabel = inputValue.trim();
    if (!newLabel || dynamicItems.some((item) => item.name.toLowerCase() === newLabel.toLowerCase())) {
      dispatch(setSnackbarAction({ message: `Category already exists.`, open: true, severity: 'error' }));
      return;
    }

    const maxId = dynamicItems.reduce((max, item) => (item.id > max ? item.id : max), 0) + 1;

    const newCategory: Category = {
      id: maxId,
      name: newLabel,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };

    setIsLoading(true);
    try {
      const response = await HelpMenuApiService.createCategory(newCategory);
      if (response?.status === 200 || response?.status === 201) {
        setDynamicItems((prevItems) => [...prevItems, newCategory]);
        setInputValue('');
        setAddPopoverOpen(false);
        dispatch(
          setSnackbarAction({
            message: `Category added successfully.`,
            open: true,
            severity: 'success',
          })
        );
        onCategoryAdded(newCategory);
      } else {
        throw new Error(`Failed to add category, server responded with status: ${response?.status}`);
      }
    } catch (error: any) {
      dispatch(
        setSnackbarAction({
          message: error.response?.data?.message || `Error adding category: ${error.message}`,
          open: true,
          severity: 'error',
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Backspace' && !inputValue) {
      event.preventDefault();
    }
  };

  const handleAutocompleteKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft') {
      event.stopPropagation();
    }
  };

  const handleInputBlur = () => {
    if (addPopoverOpen) {
      inputRef.current?.focus();
    }
  };

  const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => (
    <Box position="relative" width="100%">
      <TextField
        {...params}
        label={selectedOptions.length > 0 ? `${selectedOptions.length} selected` : selectAllLabel}
        variant={variant as any}
        required
        onKeyDown={handleAutocompleteKeyDown}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <div>
              {params.InputProps.endAdornment}
              <Box display="flex" justifyContent="flex-end">
                {' '}
                <IconButton
                  onClick={handleAddClick}
                  color="primary"
                  size="small"
                  sx={{
                    position: 'absolute',
                    right: 30, // Align to the right edge of the TextField
                    top: '50%', // Center vertically
                    transform: 'translateY(-50%)', // Ensure vertical centering
                    border: '1px solid',
                    backgroundColor: 'transparent', // Use theme color
                    borderColor: 'primary.main', // Color of the border
                    color: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'primary.light', // Light background on hover for contrast
                      color: 'primary.main',
                    },
                    width: 30, // Specific width for square shape
                    height: 30, // Specific height for square shape
                    borderRadius: '0', // Square edges
                  }}
                >
                  <AddIcon />
                </IconButton>
                <Popover
                  open={addPopoverOpen}
                  anchorEl={anchorEl}
                  onClose={handleAddClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
                    <TextField
                      autoFocus
                      placeholder={t('Create_categories')}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyPress={(e) => e.key === 'Enter' && handleAddNewCategory()}
                      onKeyDown={handleKeyDown}
                      onBlur={handleInputBlur}
                      fullWidth
                      inputRef={inputRef}
                    />
                    <IconButton onClick={handleAddClose} color="primary" sx={{ color: 'red' }}>
                      <CloseIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleAddNewCategory}
                      color="primary"
                      sx={{ color: 'green' }}
                      disabled={inputValue.trim().length < 3 || isLoading}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Box>
                </Popover>
              </Box>
            </div>
          ),
        }}
      />
    </Box>
  );
  const handleDelete = (categoryId: number) => {
    let shouldArchiveCategory = true;

    setSelectedOptions((prevOptions) => {
      const categoryToDelete = prevOptions.find((category) => category.id === categoryId);

      if (categoryToDelete) {
        if (availableCategories?.some((category) => category.id === categoryToDelete.id)) {
          onCategoryDeletionAttempt();
          shouldArchiveCategory = false;
          return prevOptions;
        }
        return prevOptions.filter((category) => category.id !== categoryId);
      }

      return prevOptions;
    });

    if (shouldArchiveCategory) {
      archiveCategory(categoryId);
    }
  };

  const archiveCategory = (categoryId: number) => {
    const categoryToArchive = selectedOptions.find((category) => category.id === categoryId);
    if (categoryToArchive) {
      const category: Category = {
        id: categoryToArchive.id,
        name: categoryToArchive.name,
        created_at: '', // Placeholder or fetch actual value if available
        updated_at: new Date().toISOString(),
      };
      onCategoryArchived(category);
    }
  };

  useEffect(() => {
    const overflowItems = selectedOptions.length - maxVisible;
    if (overflowItems <= 0 && popoverOpen) {
      setPopoverOpen(false);
    }
  }, [selectedOptions, popoverOpen, maxVisible]);

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);

  const avatarsToRender: any = [];

  visibleItems.forEach((option, index) => {
    avatarsToRender.push(
      <CustomAvatar
        displayAvatar={false}
        sx={{ marginRight: '10px', padding: '8px 1px' }}
        key={option.id}
        name={option.name}
        onClose={() => handleDelete(option.id)}
      />
    );

    if (index === maxVisible - 1 && selectedOptions.length > maxVisible) {
      const remainingCount = selectedOptions.length - maxVisible;
      avatarsToRender.push(
        <Box
          component="span"
          onClick={handleAvatarGroupClick}
          sx={{
            cursor: 'pointer',
            backgroundColor: '#808080',
            borderRadius: '50%',
            padding: '2px',
            display: 'inline-flex',
            color: 'white',
            width: '24px',
            height: '24px',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '12px',
            fontSize: '12px',
          }}
          key="overflow"
        >
          +{remainingCount}
        </Box>
      );
    }
  });

  return (
    <>
      <MUIAutocomplete
        disableCloseOnSelect
        key={key}
        sx={{
          maxHeight: '150px',
          '& .Mui-focused, & .MuiAutocomplete-inputFocused': {
            backgroundColor: 'transparent',
            outline: 'none',
          },
          ...sx,
        }}
        multiple
        size="small"
        options={dynamicItems}
        value={selectedOptions}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        filterOptions={filterOptions}
        onChange={handleOptionChange}
        renderOption={optionRenderer}
        renderInput={renderInput}
        renderTags={() => null}
        ListboxProps={{
          style: {
            maxHeight: '320px',
            overflow: 'auto',
          },
        }}
        clearIcon={null}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Tooltip placement="bottom-start" title="View More Categories">
          <AvatarGroup
            max={maxVisible + 1}
            sx={{ '& .MuiAvatar-root': { width: 30, height: 30, marginRight: 0.5 } }}
            onClick={handleAvatarGroupClick}
          >
            {avatarsToRender}
          </AvatarGroup>
        </Tooltip>

        <Popover
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          PaperProps={{
            style: {
              minWidth: 100,
              maxWidth: 300,
              minHeight: 100,
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
              backgroundColor: 'transparent',
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              maxHeight: 200,
              minWidth: 100,
              maxWidth: 300,
              overflowY: 'auto',
              p: 2,
            }}
          >
            {overflowItems.map((option) => (
              <CustomAvatar
                displayAvatar={false}
                key={option.id}
                name={option.name}
                onClose={() => handleDelete(option.id)}
                sx={{ marginBottom: 1 }}
              />
            ))}
          </Box>
        </Popover>
      </Box>
    </>
  );
};

export default MultiSelectVideoCategory;
