const en = {
  To_Duplicate: '',
  No_client_found: 'No client found',
  scope_id: 'Scope',
  consultant_fullname: 'Consultant ',
  delivery_manager_fullname: 'Delivery Manager',
  delivery_manager: 'Delivery Manager',
  client_fullname: 'Client ',
  forecasted_date: 'New expected date',
  delivery_date: 'Delivery date',
  complexity_name: 'Complexity',
  pending: 'Pending',
  drafted: 'Drafted',
  archived: 'Deleted',
  archive: 'Archive',
  production: 'Production',
  management_catalog: 'Catalog',
  management_material: 'Time & Material',
  material_drafts: 'Drafts',
  material_production: 'Production',
  material_closed: 'Closed',
  material_deleted: 'Deleted',
  closed: 'Closed',
  validated: 'Validated',
  actions: 'Actions',
  comments: 'Comments',
  amount: 'Price',
  cancellation_reason: 'Cancellation reason',
  modification_reason: 'Modification reason',
  workunit_name: 'Deliverable',
  workunit_reference: 'Reference',
  Assess_and_sign: 'Assess and sign',
  Approve_and_sign: 'Approve and sign',
  Cancelation_reason: 'Cancelation reason',
  No_workunit_in_catalog: 'No workunit in catalog',
  Validated_bm: 'Validated by delivery manager',
  Client_acceptance_at_completion: 'Accepted by client at completion',
  Estimated_at_completion: 'Submitted by consultant',
  Representative: 'Representative',
  Nothing: 'No results found',
  Deliverables_evaluation: 'Deliverables evaluation',
  Accept: 'Accept',
  Deliverables_acceptance: 'Deliverables acceptance',
  Validate_selected_deliverables: 'Validate selected deliverables',
  Deliverables_validation: 'Deliverables validation',
  Deliverable_submission: 'Deliverables submission',
  Cancel_submission: 'Cancel submission',
  Cancel_validation: 'Cancel validation',
  Submit_selected_deliverables: 'Submit selected deliverables',
  Cancel_selected_deliverables: 'Cancel selected deliverables',
  Cancel_submission_selected_deliverables: 'Cancel submission of selected deliverable(s)',
  Cancel_validation_selected_deliverables: 'Cancel validation of selected deliverable(s)',
  WU_Parameters: 'Workunit parameters',
  Quick_action: 'Quick actions',
  Quick_view: 'Quick View',
  items_selected: ' items selected',
  Add_client: 'Add client',
  Add_catalog: 'New catalog',
  Deliverable_projection: 'Deliverable projection',
  // Date_should_be_superior_to: 'Date should be superior to',
  // Date_should_be_inferior_to: 'Date should be inferior to',
  month_should_be_inferior_to: 'The end month must be later than',
  Date_should_be_superior_to: 'The date must be later than',
  Date_should_be_inferior_to: 'The date must be earlier than',
  Date_invalid: 'Date invalid',
  Edit_order: 'Edit order',
  Start_frequency_date: 'Starting frequency date',
  End_frequency_date: 'Ending frequency date',
  No_scope: 'No scope',
  Deliverable_name: 'Deliverable name',
  Contract_reference: 'Reference',
  Order_price: 'Order price',
  Apply_discount: 'Apply discount',
  Final_price: 'Final price',
  Quote_reference: 'Quote reference',
  Confirm_delete_deliverables: 'Are you sure you want to delete this deliverable ?',
  Text_not_affilied_1: 'You can try disconnecting and reconnecting.',
  Text_not_affilied_2: 'Please contact an administrator if the issue persist.',
  Initial_Deliverable_date: 'Initial Delivery Date',
  Reason: 'Comment your change',
  Enter_text_here: 'Enter text here',
  New_expected_date: 'New expected date',
  Modify_deliverable: 'Modify deliverable ',
  Client_not_indicated: 'Client not indicated',
  Error_input_date_max_date_inferior_start_date: 'End date should be equal or superior to start date',
  TRIGGER_VALIDATION_SUMMARY: 'This action will trigger the download of an Excel file.',
  Quote_summary: 'Quote summary',
  Select_catalog: 'Select a catalog',
  Affair_date: 'Engagement date',
  Engagement_date: 'Engagement date',
  Project_name: 'Project name',
  Project_ref: 'Project name and Ref',
  Otp_ref: 'OTP name and Ref',
  Text2_chrono_drawer: 'Please complete it below before going ahead',
  Text1_chrono_drawer: 'There is no reference chrono for this order. ',
  Text2_affair_drawer: 'Please select one engagement below before going ahead',
  Text2_client_drawer: 'Please select one client below before going ahead',
  Text1_affair_drawer: 'There is no engagement for this order',
  Text1_client_drawer: 'There is no client for this order',
  Complete_affair: 'Complete order',
  Quarter_displayed: 'View by quarters',
  Forecasted_date: 'Forecasted date',
  Reforecasted_date: 'Reforecasted date',
  Forecasted_delivery: 'Forecasted delivery',
  Select_forecasted_date: 'Select forecasted date',
  Affair_not_indicated: 'Engagement not indicated',
  Draft_reference: 'Chrono reference',
  Draft_reference_text: 'Reference (chrono)',
  Go_ahead: 'Proceed',
  CONTACT_ADMIN_CONFIG_TENANT_WORKMONTH: 'Missing data. Contact space administrator',
  To_deliver: 'To deliver',
  Acceptation_with_reserve: 'Acceptation with reserve',
  Validated_with_reserve: 'Validation with reserve',
  Delivery_manager: 'Delivery manager',
  Validate_order: 'Validate quote',
  Current_order_datagrid_no_row_overlay: 'Select work units in catalog, delivery month above and add it on your order.',
  All_project: 'All scopes',
  Save_for_later: 'Save for later',
  Order_summary: 'Summary',
  My_order: 'My order',
  Enter_professional_info_tm: 'Professional Info',
  Engagement_by_name_tm: 'Engagement by Name',
  Engagement_by_name_text_tm: 'Select a name',
  Engagement_by_otp_code_tm: 'Engagement by OTP code',
  Engagement_by_otp_code_text_tm: 'Select an OTP Code',
  Reference_chrono_tm: 'Reference (chrono)',
  Reference_chrono_text_tm: 'Enter chrono',
  Representative_select_tm: 'Representative (select a delegate to modify this order on your behalf)',
  Representative_select_text_tm: 'Select a representative',
  Project_name_tm: 'Project Name',
  Project_name_text_tm: 'Enter project name',
  Spec_reference_tm: 'Spec Reference',
  Spec_reference_text_tm: 'Enter spec reference',
  Start_date_tm: 'Start Date',
  Date_text_tm: 'Enter date',
  End_date_tm: 'End Date',
  purchase_order: 'Purchase Order',
  Purchase_order_text_tm: 'Enter purchase order',
  Scopes_tm: 'Scope(s)',
  Scopes_text_tm: 'Select scope on the list or create your own',
  Stake_holders_tm: 'Stakeholders',
  Delivery_managers_tm: 'Delivery Manager(s)',
  Head_text_tm: 'Enter a name or select on the list',
  Consultants_tm: 'Consultant(s)',
  Client_name_tm: 'Client Name',
  Client_connection_to_platform_tm: "Client's connection to the platform",
  Enter_affair_parameters: 'Enter engagement parameters',
  Create_new_order: 'Create new order',
  Create_time_material_order_tm: 'Create Time & Materials Order',
  Save_as_draft_tm: 'Save as Draft',
  Validate_tm: 'Validate',
  Cancel_tm: 'Cancel',
  Affair_parameters: 'Engagement parameters',
  Edit_deliverables: 'Edit deliverables',
  Some_deliverable_cannot_be_edited: 'Some deliverable are not editable',
  Reload_user_on_tenant_permissions: 'Reload user on tenant permissions',
  Reload_permissions: 'Reload user permissions',
  Users_roles: 'Users roles',
  Customer_Administration: 'Customer Administration',
  Delete_deliverables: 'Delete deliverable(s)',
  These_deliverables_are_not_deletable: 'Unable to delete selected deliverable(s)',
  Some_of_These_deliverables_are_not_deletable: 'Some deliverable(s) cannot be deleted',
  Name_deliverable: 'Deliverable name',
  Deliverable_Reference: 'Deliverable reference',
  Deliverable_Description: 'Deliverable description',
  Deliverable_Content: 'Deliverable content',
  Users_gestions: 'Users gestion',
  Content_new_deliverable: 'Content new deliverable',
  Roles: 'Roles',
  DRAG_AND_DROP_TEXT_CATALOG: 'Drag and drop .xlxs catalog here',
  Download_template: 'Download template',
  Customer_Validation: 'Customer validation',
  Select_delivery_date: 'Select delivery date',
  Delivery_date: 'Delivery date',
  not_attribued: 'Not attribued',
  Consultant_submission: 'Consultant submission',
  Clear_filters: 'Clear filters',
  Excellent: 'Excellent',
  Above_expectations: 'Above expectations',
  Average: 'Average',
  Below_expectations: 'Below expectations',
  Not_Acceptable: 'Not Acceptable',
  PARTIAL_DUPLICATE: 'Only custom deliverables can be duplicated',
  Save_change: 'Save',
  Not_submited: 'Not submited',
  Refused_TL: 'Refused by DM',
  Reserve_TL: 'Reserve by DM',
  Refused_customer: 'Refused by customer',
  Reserve_customer: 'Reserve by customer',
  Validated_TL: 'Validated by DM',
  Validated_customer: 'Validated by customer',
  Diffuse_external: 'External broadcasting',
  Is_private: 'Is private',
  Charge: 'Workload',
  Welcome_back: 'Welcome back',
  Mark_all_as_read: 'Mark all as read',
  client_acceptance: 'Client acceptance',
  Send_rating: 'Send rating',
  See_all_notifications: 'See all notifications',
  See_less_notifications: 'See less notifications',
  More_filters: 'More filters',
  Re_submit: 'Submit again',
  Validate_and_assess: 'Validate and assess',
  Validate_with_reserve: 'Validate with reserve',
  To_be_submitted: 'To be submitted',
  To_be_validated: 'To be validated',
  To_be_confirmed: 'To be accepted',
  To_be_assessed: 'To be assessed',
  mission_refusal_message: 'A deliverable has been refused',
  mission_accept_partial_message: 'A deliverable has been accepted with reserve',
  mission_just_shipped_message: 'A deliverable has been send',
  mission_just_confirmed: 'A deliverable has been confirmed',
  scope_new_assignation_message: 'You have been assigned to an new scope on an order',
  mission_new_client_assignation_message: 'You have been assigned to review some deliverable on an order',
  mission_new_consultant_assignation_message: 'You have been assigned to some deliverables on an order',
  mission_overdue_message: 'Some deliverables are overdue',
  mission_bad_ranking_message: 'A deliverable has been badly ranked',
  Submit_again: 'Re-submit',
  New_user: 'New user',
  Save_user: 'Save user',
  Last_name: 'Last Name',
  First_name: 'First Name',
  bm: 'Business Manager',
  consultant: 'Consultant',
  customer: 'Customer',
  head: 'Team leader',
  admin: 'Administrator',
  support_bu: 'Support BU',
  team_leader_plus: 'Team leader +',
  team_leader: 'Team leader',
  ddcc: 'DDCC',
  bu: 'Bu',
  butl: 'BUTL',
  Open_access: 'Open access',
  '2_characters_Minimun': '2 characters min',
  Name_catalog: 'Catalog name',
  Create_an_order: 'Select',
  Order_parameter: 'Order parameters',
  Save_modifications: 'Save modifications',
  Submit_refusal: 'Submit refusal',
  Submit_accepted: 'Submit acceptation',
  Accept_with_reserve: 'Accept with reservation',
  Leave_a_comment: 'Leave a comment',
  Select_a_consultant: 'Select a consultant',
  Select_a_scope: 'Select a scope',
  Select_a_customer: 'Select a customer',
  Validity_to: 'Validity to',
  Validity_from: 'Validity from',
  Internal_contact: 'Internal contact',
  Create_Workunit: 'Create Workunit',
  Client_catalog: 'Client',
  Validity_period: 'Validity Period',
  Last_updated: 'Last Updated',
  Create_catalog: 'Create catalog',
  Update_catalog: 'Update catalog',
  Create_categories: 'Create categories',
  Catalog_workunits: 'Catalog Workunits',
  Scope_name: 'Scope',
  Create_client: 'Create client',
  Ref_order: 'Reference order',
  Reference_spec: 'Reference',
  Mission_period: 'Order period',
  Add_a_new_order: 'Add a new quote',
  Go_to_selection_workunits: 'Selection',
  Go_to_summary: 'Summary',
  Go_to_parameters_workunits: 'Parameters',
  Go_to_parameters_affectations_workunits: 'Assignement',
  Remove_this_element: 'Remove this workunit',
  Cant_remove_because_used: "Can't remove because it's used",
  is_minimun_because_assignations: 'is the minimun because it is assigned',
  Ref_order_items: 'Purchase order reference',
  Ref_workspace: 'Reference work space',
  Total_charge: 'Total workload',
  Otp_code: 'Code OTP',
  Choose_by_OTP_code: 'Choose engagement by otp',
  Choose_by_name: 'Choose engagement by name',
  Affair_name: 'Engagement name',
  Total_Price: 'Total price',
  Not_defined: 'Not defined',
  Validated_by: 'Delivery manager',
  Number_of_workunits: 'N° workunits',
  Name_spec: 'Project Name',
  Ref_spec: 'Spec Reference',
  To_Duplicate: '',
  Edit_Order_Workunit: 'Assign selected items',
  Start_date: 'Start date',
  End_date: 'End date',
  Add_selected: 'Add selection',
  Edit_workunit: 'Edit workunit ',
  Price: 'Price',
  Load_Estimation: 'Load estimation',
  Select_validity_dates: 'Select validity dates',
  ADD_COMMENT: 'Add a comment',
  Accept_standard: 'Acceptance standard',
  Level_standard: 'Level standard',
  Deliverables: 'Deliverables',
  ADD_WORKUNIT_LIVRABLE: 'Add a deliverable',
  Create_workunit: 'Creation workunit',
  REFERENCE_OF_WORKUNIT: 'Reference of the workunit',
  NAME_OF_NEW_WORKUNIT: 'Name of the workunit',
  DESCRIPTION_NEW_WORKUNIT: 'Description of the workunit',
  INPUT_NEW_WORKUNIT: 'Acceptable standard description',
  OUTPUT_NEW_WORKUNIT: 'Acceptable output description',
  LEVEL_STANDARD_WORKUNIT: 'Level standard description',
  ACCEPT_STANDARD_WORKUNIT: 'Acceptable standard description',
  OUTPUTS: 'Outputs',
  INPUTS: 'Inputs',
  Submitted: 'Submitted',
  Accepted: 'Accepted',
  Client_Accepted: 'Accepted by client',
  Refused: 'Refused',
  Client_Refused: 'Refused by client',
  Reset_filter: 'Reset_filter',
  Description_new_deliverable: 'Describe your deliverable',
  Validate_the_modifications: 'Validate modifications',
  Disconnect: 'Disconnect',
  Active_user_avatar: 'Your avatar',
  Close: 'Close',
  Back_up: 'Back up',
  Saving_location: 'Saving location',
  Estimate_charge: 'Estimate workload',
  Optional: 'Optional',
  Order_number: 'Order N°',
  Add_a_number: 'Add a number',
  Date_delivery: 'Expected delivery date',
  Add_this_new_deliverable: 'Add this new deliverable',
  Select_the_start_date: 'Select the start date',
  Edit_deliverable: 'Edit the deliverable',
  Generate_sheet: 'Export',
  Duplicate_deliverable: 'Duplicate a deliverable',
  Done: 'Completed',
  Open: 'Open',
  One_time: 'One time only',
  Weekly: 'Weekly',
  Monthly: 'Monthly',
  Other: 'Other',
  Status: 'Status',
  Name: 'Name',
  Reference: 'Reference',
  Edit: 'Edit',
  Deliverables: 'Deliverables',
  Deliverable_date: 'Delivery date',
  Frequency: 'Frequency',
  Submitted: 'Submitted',
  Validated: 'Validation',
  Accepted: 'Accepted',
  Rating: 'Note',
  Comment: 'Comment',
  Action: 'Actions',
  Add_new_deliverable: 'Add new deliverable',
  REFERENCE_OF_NEW_DELIVERABLE: 'Reference of the deliverable',
  NAME_OF_NEW_DELIVERABLE: 'Name of the deliverable',
  added: 'Added',
  custom: 'Custom',
  updated: 'Updated',
  cancelled: 'Cancelled',
  tags: 'Tags',
  reference: 'Reference',
  name: 'Deliverables',
  workunit_desc: 'Description',
  description: 'Description',
  content: 'Content',
  content2: 'Content 2',
  content3: 'Content 3',
  content4: 'Content 4',
  content5: 'Content 5',
  workload: 'Workload',
  price: 'Price',
  total: 'Total',
  complexity: 'Complexity',
  deliverableDate: 'Initial delivery date',
  forcastedDate: 'New expected date',
  scope: 'Scope',
  client: 'Client',
  edit_client: 'Edit client',
  submitted: 'Consultant submission',
  validated: 'DM assignment',
  dm_validation: 'DM validation',
  accepted: 'Client acceptation',
  rating: 'Note',
  submit: 'Submit',
  edit: 'Modify',
  restore: 'Restore',
  restore_account: 'Restore Account',
  restore_success: 'The User account has been restored successfully!',
  cancel_validation: 'Cancel validation',
  cancel_submission: 'Cancel submission',
  cancel: 'Cancel',
  delete: 'Delete',
  cancel_assessment: 'Cancel assessment',
  External_broadcasted: 'External broadcasted',
  cancel_refusal: 'Cancel refusal',
  cancel_comment: 'Cancel comment',
  cancel_acceptance: 'Cancel acceptance',
  not_disclosed: 'Not disclosed',
  select_wu_catalog: 'Select WU in catalog for each delivery month and add to your quote',
  no_archived_catalogs: 'No archived catalogs',
  no_archived_clients: 'No archived clients',
  document_title: 'Document title',
  search_by_name_or_ref: 'Search by project name or reference',
  create_new_quote: 'Create new quote',
  add_deliverable: 'Add deliverable',
  reset_all: 'Reset all',
  archives: 'Deleted',
  used_complexity: 'This complexity is used in a workunit',
  details: 'Details',
  no_alerts: 'You currently do not have any alerts',
  see_details: 'See details',
  hide_details: 'Hide details',
  extract_in_pdf: 'Extract in PDF',
  extract_in_excel: 'Extract in Excel',
  reset_password: 'Resend temporary password',
  confirm_client_reset_password: 'Are you sure you want to resend the temporary password of this client?',
  confirm_reset_password: 'Yes, resend',
  remember_check_spams: 'Remember to check spams',
  client_parameters: 'Client parameters',
  yes: 'Yes',
  no: 'No',
  client_connection_platform: "Client's connection to the platform",
  accept_dm: 'Deliverable accepted by Internal M|P user in place of the client',
  reserve_dm: 'Deliverable accepted with reserve by Internal M|P user in place of the client',
  refuse_dm: 'Deliverable refused by Internal M|P user in place of the client',
  assessed_dm: 'Deliverable assessed by Internal M|P user in place of the client',
  financial: 'Financial',
  technical: 'Technical',
  document_location: 'Document location',
  signed_by_dm: 'Process realized by M|P internal user',
  deactivate_schedule_anonymize: 'Deactivate Account & Schedule Anonymization',
  deactivate_anonymize: 'Deactivate Account & Anonymize GDPR Data Now',
  deactivate: 'Deactivate',
  deactivate_user: 'Deactivate User',
  unassign_user: 'Unassign User',
  unassign_user_button: 'Yes, Unassign User',
  confirm_unassign_user: 'Are you sure you want to unassign the user from this client?',
  schedule_anonymize_tooltip:
    'Deactivate the user account now. Anonymization of data will be scheduled 5 years post-deactivation, adhering to GDPR policies',
  anonymize_tooltip: 'Deactivate the user account and immediately anonymize all personal data',
  open_access_confirm:
    'You are about to open access to the client. He will receive an email with a temporary password.',
  continue: 'Continue',
  open_access_temp_pass: 'Temporary Password for Client',
  no_video_found: 'No video found',
  no_userGuide_found: 'No user guide found',
};

export default en;
