import { GridCellParams, GridColDef, GridColumnHeaderParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import SearchHeaderCell from 'components/SearchHeaderCell/SearchHeaderCell';
import AcceptedCell from 'features/Deliverables/DeliverableTable/Cells/AcceptedCell/AcceptedCell';
import ActionsCell from 'features/Deliverables/DeliverableTable/Cells/ActionsCell/ActionsCell';
import AmountCell from 'features/Deliverables/DeliverableTable/Cells/AmountCell/AmountCell';
import ClientCell from 'features/Deliverables/DeliverableTable/Cells/ClientCell/ClientCell';
import CommentsCell from 'features/Deliverables/DeliverableTable/Cells/CommentsCell/CommentsCell';
import ComplexityCell from 'features/Deliverables/DeliverableTable/Cells/ComplexityCell/ComplexityCell';
import ConsultantCell from 'features/Deliverables/DeliverableTable/Cells/ConsultantCell/ConsultantCell';
import ContentsCell from 'features/Deliverables/DeliverableTable/Cells/ContentsCell/ContentsCell';
import DeliverableDateCell from 'features/Deliverables/DeliverableTable/Cells/DeliverableDateCell/DeliverableDateCell';
import DescriptionCell from 'features/Deliverables/DeliverableTable/Cells/DescriptionCell/DescriptionCell';
import DiscountCell from 'features/Deliverables/DeliverableTable/Cells/DiscountCell/DiscountCell';
import ForcastedDateCell from 'features/Deliverables/DeliverableTable/Cells/ForcastedDateCell/ForcastedDateCell';
import NameCell from 'features/Deliverables/DeliverableTable/Cells/NameCell/NameCell';
import RatingCell from 'features/Deliverables/DeliverableTable/Cells/RatingCell/RatingCell';
import ReferenceCell from 'features/Deliverables/DeliverableTable/Cells/ReferenceCell/ReferenceCell';
import ReviewCell from 'features/Deliverables/DeliverableTable/Cells/ReviewCell/ReviewCell';
import ScopeCell from 'features/Deliverables/DeliverableTable/Cells/ScopeCell/ScopeCell';
import SubmittedCell from 'features/Deliverables/DeliverableTable/Cells/SubmittedCell/SubmittedCell';
import TagsCell from 'features/Deliverables/DeliverableTable/Cells/TagsCell/TagsCell';
import ValidatedCell from 'features/Deliverables/DeliverableTable/Cells/ValidatedCell/ValidatedCell';
import WorkloadCell from 'features/Deliverables/DeliverableTable/Cells/WorkloadCell/WorkloadCell';
import OrderworkunitsIDCell from 'features/Deliverables/DeliverableTable/Cells/OrderworkunitsIDCell/OrderworkunitsIDCell';
import useUserRoles from 'hooks/useUserRoles';
import { find, forEach, map } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MissionComment from 'types/entities/MissionComment';
import OrderWorkunit from 'types/entities/OrderWorkunit';
import Scope from 'types/entities/Scope';
import User from 'types/entities/User';
import WorkunitComplexity from 'types/entities/WorkunitComplexity';
import { DeliverableTableType } from 'types/enums/DeliverableTableType';
import CancellationReasonCell from '../DeliverableTable/Cells/CancellationReasonCell/CancellationReasonCell';
import DeliveryManagerCell from '../DeliverableTable/Cells/DeliveryManagerCell/DeliveryManagerCell';
import ModificationReasonCell from '../DeliverableTable/Cells/ModificationReasonCell/ModificationReasonCell';
import PoReferenceCell from '../DeliverableTable/Cells/PoReferenceCell/PoReferenceCell';
import { useSelector } from 'store/hooks';

type DeliveryTableGridCellParams<T> = GridCellParams<T, OrderWorkunit, any>;
type DeliveryTableGridColumnHeaderParams<T> = GridColumnHeaderParams<T, GridValidRowModel, any>;

export const columnField = {
  ACCEPTED: 'client_acceptance',
  ACTIONS: 'actions',
  AMOUNT: 'amount',
  CANCELLATION_REASON: 'cancellation_reason',
  CLIENT: 'client',
  DELIVERY_MANAGER: 'delivery_manager',
  COMMENTS: 'comments',
  COMPLEXITY: 'complexity',
  CONSULTANT: 'consultant',
  CONTENT: 'content',
  CONTENT2: 'content2',
  CONTENT3: 'content3',
  CONTENT4: 'content4',
  CONTENT5: 'content5',
  DELIVERABLE_DATE: 'deliverableDate',
  DESCRIPTION: 'workunit_desc',
  DISCOUNT: 'discount',
  FORECASTED_DATE: 'forcastedDate',
  MODIFICATION_REASON: 'modification_reason',
  NAME: 'workunit_name',
  RATING: 'rating',
  REFERENCE: 'workunit_reference',
  REVIEW: 'review',
  SCOPE: 'scope',
  SUBMITTED: 'submitted',
  TAGS: 'tags',
  VALIDATED: 'dm_validation',
  WORKLOAD: 'workload',
  PO_REFERENCE: 'purchase_order',
  ID: 'OrderWorkUnits_ID',
} as const;

const defaultColumnParams: GridColDef = {
  field: '',
  headerName: '',
  align: 'left',
  headerAlign: 'center',
  filterable: false,
  sortable: true,
  flex: 1,
};

export const columnsParams: GridColDef[] = [
  {
    field: columnField.ID,
    renderCell: (params: DeliveryTableGridCellParams<number>) => <OrderworkunitsIDCell {...params.row} />,
    resizable: true,
    align: 'center',
    minWidth: 100,
  },
  {
    field: columnField.ACCEPTED,
    renderCell: (params: DeliveryTableGridCellParams<number>) => <AcceptedCell row={params.row} />,
    resizable: true,
    align: 'center',
    minWidth: 100,
  },
  {
    field: columnField.ACTIONS,
    renderCell: (params: DeliveryTableGridCellParams<null>) => <ActionsCell {...params.row} />,
    resizable: true,
    align: 'center',
    minWidth: 50,
  },
  {
    field: columnField.AMOUNT,
    renderCell: (params: DeliveryTableGridCellParams<number>) => <AmountCell {...params.row} />,
    minWidth: 60,
  },
  {
    field: columnField.CANCELLATION_REASON,
    renderCell: (params: DeliveryTableGridCellParams<number>) => <CancellationReasonCell {...params.row} />,
    minWidth: 60,
  },
  {
    field: columnField.CLIENT,
    renderCell: (params: DeliveryTableGridCellParams<User>) => <ClientCell {...params.row} />,
    minWidth: 60,
  },
  {
    field: columnField.COMMENTS,
    renderCell: (params: DeliveryTableGridCellParams<MissionComment[]>) => <CommentsCell {...params.row} />,
    resizable: true,
    minWidth: 50,
    align: 'center',
  },
  {
    field: columnField.COMPLEXITY,
    renderCell: (params: DeliveryTableGridCellParams<WorkunitComplexity>) => <ComplexityCell {...params.row} />,
    minWidth: 60,
  },
  {
    field: columnField.DELIVERY_MANAGER,
    renderCell: (params: DeliveryTableGridCellParams<User>) => <DeliveryManagerCell {...params.row} />,
    resizable: true,
    minWidth: 80,
  },
  {
    field: columnField.CONSULTANT,
    renderCell: (params: DeliveryTableGridCellParams<User>) => <ConsultantCell {...params.row} />,
    resizable: true,
    minWidth: 80,
  },
  {
    field: columnField.CONTENT,
    minWidth: 100,
    renderCell: (params: DeliveryTableGridCellParams<string>) => (
      <ContentsCell row={params.row} target={columnField.CONTENT} />
    ),
    renderHeader: (params: DeliveryTableGridColumnHeaderParams<string>) => <SearchHeaderCell {...params} />,
  },
  {
    field: columnField.CONTENT2,
    minWidth: 100,
    renderCell: (params: DeliveryTableGridCellParams<string>) => (
      <ContentsCell row={params.row} target={columnField.CONTENT2} />
    ),
    renderHeader: (params: DeliveryTableGridColumnHeaderParams<string>) => <SearchHeaderCell {...params} />,
  },
  {
    field: columnField.CONTENT3,
    minWidth: 100,
    renderCell: (params: DeliveryTableGridCellParams<string>) => (
      <ContentsCell row={params.row} target={columnField.CONTENT3} />
    ),
    renderHeader: (params: DeliveryTableGridColumnHeaderParams<string>) => <SearchHeaderCell {...params} />,
  },
  {
    field: columnField.CONTENT4,
    minWidth: 100,
    renderCell: (params: DeliveryTableGridCellParams<string>) => (
      <ContentsCell row={params.row} target={columnField.CONTENT4} />
    ),
    renderHeader: (params: DeliveryTableGridColumnHeaderParams<string>) => <SearchHeaderCell {...params} />,
  },
  {
    field: columnField.CONTENT5,
    minWidth: 100,
    renderCell: (params: DeliveryTableGridCellParams<string>) => (
      <ContentsCell row={params.row} target={columnField.CONTENT5} />
    ),
    renderHeader: (params: DeliveryTableGridColumnHeaderParams<string>) => <SearchHeaderCell {...params} />,
  },
  {
    field: columnField.DELIVERABLE_DATE,
    renderCell: (params: DeliveryTableGridCellParams<string>) => <DeliverableDateCell {...params.row} />,
    minWidth: 60,
  },
  {
    field: columnField.DESCRIPTION,
    renderCell: (params: DeliveryTableGridCellParams<string>) => <DescriptionCell {...params.row} />,
    renderHeader: (params: DeliveryTableGridColumnHeaderParams<string>) => <SearchHeaderCell {...params} />,
    minWidth: 60,
  },
  {
    field: columnField.DISCOUNT,
    renderCell: (params: DeliveryTableGridCellParams<number>) => <DiscountCell {...params.row} />,
    minWidth: 60,
  },
  {
    field: columnField.FORECASTED_DATE,
    renderCell: (params: DeliveryTableGridCellParams<string>) => <ForcastedDateCell {...params.row} />,
    minWidth: 60,
  },
  {
    field: columnField.MODIFICATION_REASON,
    renderCell: (params: DeliveryTableGridCellParams<string>) => <ModificationReasonCell {...params.row} />,
    minWidth: 60,
  },
  {
    field: columnField.NAME,
    renderCell: (params: DeliveryTableGridCellParams<string>) => <NameCell {...params.row} />,
    renderHeader: (params: DeliveryTableGridColumnHeaderParams<string>) => <SearchHeaderCell {...params} />,
    minWidth: 100,
  },
  {
    field: columnField.RATING,
    renderCell: (params: DeliveryTableGridCellParams<string | null>) => <RatingCell {...params.row} />,
    minWidth: 60,
    resizable: true,
    align: 'center',
  },
  {
    field: columnField.REFERENCE,
    renderCell: (params: DeliveryTableGridCellParams<string>) => <ReferenceCell {...params.row} />,
    renderHeader: (params: DeliveryTableGridColumnHeaderParams<string>) => <SearchHeaderCell {...params} />,
    minWidth: 60,
  },
  {
    field: columnField.REVIEW,
    renderCell: (params: DeliveryTableGridCellParams<number>) => <ReviewCell {...params.row} />,
    minWidth: 60,
  },
  {
    field: columnField.SCOPE,
    renderCell: (params: DeliveryTableGridCellParams<Scope>) => <ScopeCell {...params.row} />,
    minWidth: 60,
  },
  {
    field: columnField.SUBMITTED,
    renderCell: (params: DeliveryTableGridCellParams<OrderWorkunit['mission_advancement_id']>) => (
      <SubmittedCell {...params.row} />
    ),
    minWidth: 100,
    align: 'center',
  },
  {
    field: columnField.TAGS,
    renderCell: (params: DeliveryTableGridCellParams<OrderWorkunit['label']>) => <TagsCell {...params.row} />,
    resizable: true,
    minWidth: 140,
  },
  {
    field: columnField.VALIDATED,
    renderCell: (params: DeliveryTableGridCellParams<OrderWorkunit['mission_advancement_id']>) =>
      params.row ? <ValidatedCell row={params.row} /> : <> </>,
    resizable: true,
    minWidth: 100,
    align: 'center',
  },
  {
    field: columnField.WORKLOAD,
    renderCell: (params: DeliveryTableGridCellParams<OrderWorkunit['charge']>) => <WorkloadCell {...params.row} />,
    minWidth: 60,
  },
  {
    field: columnField.PO_REFERENCE,
    renderCell: (params: DeliveryTableGridCellParams<OrderWorkunit['charge']>) => <PoReferenceCell {...params.row} />,
    renderHeader: (params: DeliveryTableGridColumnHeaderParams<string>) => <SearchHeaderCell {...params} />,
    resizable: true,
    minWidth: 100,
    align: 'center',
  },
];

type ColumnsFields = { [name: string]: string[] };

export const deliverableColumnsFields: ColumnsFields = {
  [DeliverableTableType.TECHNICAL]: [
    columnField.TAGS,
    columnField.ID,
    columnField.SCOPE,
    columnField.REFERENCE,
    columnField.NAME,
    columnField.PO_REFERENCE,
    columnField.COMPLEXITY,
    columnField.DESCRIPTION,
    columnField.CONTENT,
    columnField.CONTENT2,
    columnField.CONTENT3,
    columnField.CONTENT4,
    columnField.CONTENT5,
    columnField.AMOUNT,
    columnField.WORKLOAD,
    columnField.DELIVERABLE_DATE,
    columnField.FORECASTED_DATE,
    columnField.MODIFICATION_REASON,
    columnField.CANCELLATION_REASON,
    columnField.CONSULTANT,
    columnField.DELIVERY_MANAGER,
    columnField.CLIENT,
    columnField.SUBMITTED,
    columnField.VALIDATED,
    columnField.ACCEPTED,
    columnField.RATING,
    columnField.COMMENTS,
    columnField.ACTIONS,
  ],
  [DeliverableTableType.FINANCIAL]: [
    columnField.TAGS,
    columnField.ID,
    columnField.SCOPE,
    columnField.REFERENCE,
    columnField.NAME,
    columnField.PO_REFERENCE,
    columnField.COMPLEXITY,
    columnField.DESCRIPTION,
    columnField.CONTENT,
    columnField.CONTENT2,
    columnField.CONTENT3,
    columnField.CONTENT4,
    columnField.CONTENT5,
    columnField.AMOUNT,
    columnField.WORKLOAD,
    columnField.DELIVERABLE_DATE,
    columnField.FORECASTED_DATE,
    columnField.MODIFICATION_REASON,
    columnField.CANCELLATION_REASON,
    columnField.CLIENT,
    columnField.VALIDATED,
    columnField.ACCEPTED,
    columnField.ACTIONS,
  ],
};

/**
 * React hook to build columns variable for Mui Datagrid with specific components
 */
export const useColumns = (columnsFields: ColumnsFields, order_type_id: number) => {
  const [t] = useTranslation();
  const userRoles = useUserRoles();

  const state = useSelector((state) => state.deliverable);
  return useMemo(() => {
    const columns: { [name: string]: GridColDef<OrderWorkunit, any, any>[] } = {};
    forEach(columnsFields, (fields, name) => {
      columns[name] = map(fields, (field) => {
        const columnParam = find(columnsParams, ['field', field]);
        return {
          ...defaultColumnParams,
          ...columnParam,
          ...{ headerName: t(field) },
        };
      });
    });
    if (userRoles.isCustomer) {
      columns[DeliverableTableType.TECHNICAL] = columns[DeliverableTableType.TECHNICAL].filter(
        (column) =>
          column.field !== columnField.CONSULTANT &&
          column.field !== columnField.WORKLOAD &&
          column.field !== columnField.SUBMITTED
      );
    }
    if (userRoles.isConsultant) {
      columns[DeliverableTableType.TECHNICAL] = columns[DeliverableTableType.TECHNICAL].filter(
        (column) => column.field !== columnField.AMOUNT
      );
    }

    if (order_type_id === 2) {
      [DeliverableTableType.TECHNICAL, DeliverableTableType.FINANCIAL].forEach((tableType) => {
        columns[tableType] = columns[tableType].filter(
          (column) =>
            column.field !== columnField.AMOUNT &&
            column.field !== columnField.WORKLOAD &&
            column.field !== columnField.COMPLEXITY
        );
      });
    }

    return columns;
  }, [columnsFields, userRoles, t, state.order?.order_type_id]);
};
