import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { selectActiveVideos, selectSelectedVideos } from 'store/selectors/HelpMenuSelectors';
import useHelpMenuService from '../hooks/useHelpMenuService';

interface HelpMenuVideoDialogDeleteProps {
  getHelpMenuList: () => Promise<void>; // Adjust type if necessary
}

const HelpMenuVideoDialogDelete: React.FC<HelpMenuVideoDialogDeleteProps> = ({ getHelpMenuList }) => {
  const [t] = useTranslation();
  const { deleteVideos } = useHelpMenuService();

  const { dispatch } = useAppState();
  const selectedVideos = useSelector(selectSelectedVideos);
  const activeVideo = useSelector(selectActiveVideos);
  const videos = activeVideo ? [activeVideo] : selectedVideos;
  const dialogs = useSelector((state) => state.dialogs);
  const close = () => {
    dispatch(closeDialogAction('videoDelete'));
  };
  const remove = async () => {
    const videoIds = videos?.map((video) => video.id);
    const deletedData = await deleteVideos(videoIds[0]);
    if (deletedData) {
      getHelpMenuList();
    }
  };

  return (
    <>
      <Dialog style={{ maxHeight: 435 }} maxWidth="xs" open={dialogs.videoDelete || false}>
        <DialogTitle>{t('Delete Videos')}</DialogTitle>
        <DialogContent>
          <div>{t('Confirm_delete_videos')}</div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={close}>
            {t('Cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={remove}>
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HelpMenuVideoDialogDelete;
