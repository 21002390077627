import React from 'react';
import VideoThumbnail from '../../../components/VideoThumbnail/VideoThumbnail';
import { useAppState } from 'store/Provider';

const HelpMenuVideos: React.FC = () => {
  const { dispatch } = useAppState();

  return <VideoThumbnail />;
};

export default HelpMenuVideos;
