import { DialogContent } from '@mui/material';
import ModalCustom from 'components/Modal/Modal';
import useDeliverableReportService from 'features/Deliverables/DeliverableReports/hooks/useDeliverableReportService';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { Rating } from 'types/enums/Rating';
import DeliverableSheetApiService from '../../../../../api/DeliverableSheetApiService';
import DocumentRatingCommentStep from './DocumentRatingCommentStep/DocumentRatingCommentStep';
import DocumentSaveLocationStep from './DocumentSaveLocationStep/DocumentSaveLocationStep';

export default function DialogMarkAsSigned({ callback }: { callback?: () => void }): JSX.Element {
  const dialogs = useSelector((state) => state.dialogs);
  const report = dialogs.data?.report || undefined;
  const [currentStep, setCurrentStep] = useState<number>(1);
  const { markAsSigned } = useDeliverableReportService();
  const [paramsForSignature, setParamsForSignature] = useState<{
    location: string;
    pdfBlob: Blob;
    assesment: Rating | null;
    comment: string;
    documentUploaded: boolean;
    invalidFileType: boolean;
  }>({
    location: '',
    pdfBlob: new Blob(),
    assesment: null,
    comment: '',
    documentUploaded: false,
    invalidFileType: false,
  });
  const { dispatch } = useAppState();
  const [t] = useTranslation();

  const convertBlobToBase64 = (blob: Blob): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(blob);
    });

  const setRate = (rate: Rating) => {
    setParamsForSignature({ ...paramsForSignature, assesment: rate });
  };

  const setComment = (comment: string) => {
    setParamsForSignature({ ...paramsForSignature, comment: comment.slice(0, 700) });
  };

  const dialogBody = (
    <DialogContent>
      {currentStep === 1 && (
        <DocumentSaveLocationStep
          paramsForSignature={paramsForSignature}
          setParamsForSignature={setParamsForSignature}
        />
      )}
      {currentStep === 2 && report && (
        <DocumentRatingCommentStep
          disableRating={report.type === 'financial'}
          rating={paramsForSignature.assesment}
          setRate={setRate}
          comment={paramsForSignature.comment}
          setComment={setComment}
        />
      )}
    </DialogContent>
  );

  const handleCloseDialog = () => {
    dispatch(closeDialogAction('markAsSigned'));
    setCurrentStep(1);
  };

  const markAsSignedConfirm = async () => {
    if (paramsForSignature.pdfBlob.type === 'application/pdf') {
      await markAsSigned(report.id, {
        ...paramsForSignature,
        comment: paramsForSignature.comment.length ? paramsForSignature.comment : ' ',
      });
      await DeliverableSheetApiService.generatePdf(report.id);
      const base64file = await convertBlobToBase64(paramsForSignature.pdfBlob);
      const base64Pdf = base64file.split(',')[1];
      const pdfUpdate =
        paramsForSignature.pdfBlob.type === 'application/pdf'
          ? await DeliverableSheetApiService.urlPdf(report.id, paramsForSignature.pdfBlob.type, base64Pdf)
          : null;
      // if (pdfUrlToUpdate) {
      //   const test = await DeliverableSheetApiService.saveContent(
      //     pdfUrlToUpdate,
      //     paramsForSignature.pdfBlob,
      //     'application/pdf'
      //   );
      //   console.log(test);
      // }
      setCurrentStep(1);
    }
    dispatch(closeDialogAction('markAsSigned'));
    if (callback) callback();
  };

  const handleConfirm = () => {
    switch (currentStep) {
      case 1:
        return () => setCurrentStep(currentStep + 1);
      case 2:
        return () => markAsSignedConfirm();
      default:
        return undefined;
    }
  };

  const returnConfirmButtonText = () => {
    switch (currentStep) {
      case 1:
        return t('Next');
      case 2:
        return t('Validate and close');
      default:
        return t('Confirm');
    }
  };

  const getConfirmDisabledStep = () => {
    switch (currentStep) {
      case 1:
        return !paramsForSignature.documentUploaded;
      case 2:
        return (
          (!paramsForSignature.assesment && report?.type !== 'financial') ||
          (paramsForSignature.assesment === 'D' && !paramsForSignature.comment)
        );
      default:
        return false;
    }
  };

  return (
    <ModalCustom
      size="md"
      modalName="markAsSigned"
      titleText={t('Mark as signed')}
      open={dialogs.markAsSigned}
      onClose={handleCloseDialog}
      onConfirm={handleConfirm()}
      confirmText={returnConfirmButtonText()}
      body={dialogBody}
      cancelText={t('Cancel')}
      confirmLoad={currentStep === 2}
      disabledConfirm={getConfirmDisabledStep()}
    />
  );
}
