import { PERMISSIONS } from 'helpers/permissions';
import useUserPermissions, { hasPermissions } from 'hooks/useUserPermissions';

/**
 * React hook that defines rights specific to the Learning Resources feature
 */
export default function useResourcesRights() {
  const userPermissions = useUserPermissions();
  return {
    canUploadVideo: hasPermissions([PERMISSIONS.UPLOAD_VIDEO], userPermissions),
    canUploadUserGuide: hasPermissions([PERMISSIONS.UPLOAD_USERGUIDE], userPermissions),
  };
}
