import { Typography, Tooltip } from '@mui/material';
import React from 'react';
import HelpMenuListVideos from 'types/entities/HelpMenuVideos';

const DescriptionCell = React.memo((row: HelpMenuListVideos) => (
  <Tooltip arrow title={row?.description ?? ''}>
    <Typography noWrap variant="body2">
      {row?.description ?? ''}
    </Typography>
  </Tooltip>
));

export default DescriptionCell;
