import { Add, Close } from '@mui/icons-material';
import { Box, Button, Drawer, IconButton, InputLabel, TextField, Typography } from '@mui/material';
import ResourceAPI from 'api/ResourceAPI';
import { Loader } from 'components';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Customer from 'types/entities/Customer';
import User from 'types/entities/User';

type FormData = {
  customer_id: number;
  department: string;
  direction: string;
  first_name: string;
  last_name: string;
  mail: string;
  phone: string;
  position: string;
  have_cognito_account: boolean;
};

type Props = {
  activeCustomer: Customer;
  open: boolean;
  dispatchOpen: () => void;
  setClient: (user: User) => void;
};

const ClientCreation: React.FC<Props> = ({ activeCustomer, open, dispatchOpen, setClient }: Props) => {
  const { dispatch } = useAppState();
  const appState = useSelector((state) => state.app);
  const [, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Form data and methods
  const [clientToCreate] = useState({
    customer_id: appState.customer?.id || activeCustomer.id,
    department: '',
    direction: '',
    first_name: '',
    is_archived: false,
    is_client: true,
    last_name: '',
    mail: '',
    phone: '',
    position: '',
    have_cognito_account: false,
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: clientToCreate,
  });

  const [t] = useTranslation();
  const onSubmit = async (data: FormData) => {
    setIsLoading(true);

    try {
      if (activeCustomer) {
        const { have_cognito_account, ...clientToSend } = data;
        const {
          data: { user_client },
        } = await ResourceAPI.post('users/clients', { ...clientToSend });
        setSubmitted(true);
        setClient(user_client);
        dispatchOpen();
      }
    } catch (e: any) {
      const result = (e as Error).message;
      if (result) {
        if (e.response && e.response.status === 422) {
          dispatch(
            setSnackbarAction({
              message: `Error with client : ${e.response.data.message}`,
              open: true,
              severity: 'error',
            })
          );
        } else dispatch(setSnackbarAction({ message: `Error with client: ${result}`, open: true, severity: 'error' }));
      } else dispatch(setSnackbarAction({ message: 'Error with client', open: true, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={dispatchOpen}
      PaperProps={{
        sx: {
          width: {
            xs: '100%',
            lg: 450,
          },
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
          <Typography variant="h2">Create client</Typography>
          <IconButton sx={{ ml: 'auto' }} color="primary" onClick={dispatchOpen}>
            <Close />
          </IconButton>
        </Box>

        {!isLoading ? (
          <Box>
            <Controller
              name="first_name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="client_first_name" error={errors.first_name && true}>
                    First name{' '}
                    <Typography display="inline" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <TextField
                    id="client_first_name"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    helperText={errors.first_name?.message}
                    error={errors.first_name && true}
                    inputProps={{ maxLength: 100 }}
                  />
                </Box>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Your must enter a first name',
                },
                pattern: {
                  value: /^[a-zA-ZÀ-ÿ\s'-]+$/,
                  message: 'Special characters and numbers are not allowed',
                },
              }}
            />
            <Controller
              name="last_name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="client_last_name" error={errors.last_name && true}>
                    Last name{' '}
                    <Typography display="inline" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <TextField
                    id="client_last_name"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    helperText={errors.last_name?.message}
                    error={errors.last_name && true}
                    inputProps={{ maxLength: 100 }}
                  />
                </Box>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Your must enter a last name',
                },
                pattern: {
                  value: /^[a-zA-ZÀ-ÿ\s'-]+$/,
                  message: 'Special characters and numbers are not allowed',
                },
              }}
            />
            <Controller
              name="position"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="client_position" error={errors.position && true}>
                    Position{' '}
                  </InputLabel>
                  <TextField
                    id="client_position"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    helperText={errors.position?.message}
                    error={errors.position && true}
                    inputProps={{ maxLength: 100 }}
                  />
                </Box>
              )}
              rules={{
                pattern: {
                  value: /^[a-zA-Z0-9\s\-'(),.&]+$/i,
                  message: 'Special characters are not allowed',
                },
              }}
            />
            <Controller
              name="mail"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="client_mail" error={errors.mail && true}>
                    Mail{' '}
                    <Typography display="inline" color="error">
                      *
                    </Typography>
                  </InputLabel>
                  <TextField
                    id="client_mail"
                    onChange={(e) => onChange(e.target.value.toLowerCase())}
                    value={value}
                    fullWidth
                    helperText={errors.mail?.message}
                    error={errors.mail && true}
                    inputProps={{ maxLength: 254 }}
                  />
                </Box>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Your must enter an email',
                },
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, // eslint-disable-line max-len
                  message: 'Please enter a valid email',
                },
              }}
            />
            <Controller
              name="department"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="client_department" error={errors.department && true}>
                    Department{' '}
                  </InputLabel>
                  <TextField
                    id="client_department"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    helperText={errors.department?.message}
                    error={errors.department && true}
                    inputProps={{ maxLength: 100 }}
                  />
                </Box>
              )}
              rules={{
                pattern: {
                  value: /^[a-zA-Z0-9\s\-'().&]+$/i,
                  message: 'Special characters are not allowed',
                },
              }}
            />
            <Controller
              name="direction"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ mb: 3 }}>
                  <InputLabel htmlFor="client_direction" error={errors.direction && true}>
                    Direction{' '}
                  </InputLabel>
                  <TextField
                    id="client_direction"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    helperText={errors.direction?.message}
                    error={errors.direction && true}
                    inputProps={{ maxLength: 100 }}
                  />
                </Box>
              )}
              rules={{
                pattern: {
                  value: /^[a-zA-Z0-9\s\-'().&]+$/i,
                  message: 'Special characters are not allowed',
                },
              }}
            />
            <Button
              data-testid="client_creation_create"
              startIcon={<Add />}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="success"
              fullWidth
            >
              {t('Create_client')}
            </Button>
          </Box>
        ) : (
          <Loader />
        )}
      </form>
    </Drawer>
  );
};
export default ClientCreation;
