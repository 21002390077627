import { Box, LinearProgress } from '@mui/material';
import {
  DataGridProProps,
  GridCallbackDetails,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridSelectionModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro/DataGridPro/DataGridPro';
import React, { useCallback, useEffect, useState } from 'react';
import {
  changePageAction,
  changePageSizeAction,
  sortHomeMenuVideoAction,
  excludeVideosAction,
  selectVideosAction,
  gethelpMenuAPIAction,
  changeSearchParamsAction,
} from 'store/actions/helpmenuActions';
import { difference } from 'lodash';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { HelpMenuTableType } from 'types/enums/HelpMenuTableType';

import { HelpmenuFields, useColumns } from '../services/HelpMenuTableServices';
import HelpMenu from '../HelpMenu';

const CustomToolbar = ({ setFilterButtonEl }: { setFilterButtonEl: any }) => {
  const { dispatch, state } = useAppState();

  // TODO : Fix GridToolbarColumnsButton props, they are temporary set to undefined
  return (
    <Box sx={{ display: 'flex', pl: 1.75 }}>
      <GridToolbarContainer sx={{ position: 'relative', ml: 'auto' }}>
        <GridToolbarColumnsButton
          ref={setFilterButtonEl}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        />
      </GridToolbarContainer>
    </Box>
  );
};

const HelpMenuTable: React.FC = () => {
  const { dispatch } = useAppState();
  const state = useSelector((state) => state.helpMenu);
  const parentAPiCall = useSelector((state) => state.helpMenu.helpMenuApiFlag);
  const data = state.currentTableType === 'Videos' ? state.datas : state.guideDatas;
  const apiRef = useGridApiRef();
  const loadings = useSelector((state) => state.loadings);
  const getLocalStorageColumnsStatusKey = (): string =>
    state.currentTableType ? `${state.currentTableType}ColumnsStatus` : '';
  const getLocalStorageColumnsOrders = useCallback(
    (): string => (state.currentTableType ? `${state.currentTableType}ColumnsOrders` : ''),
    [state.currentTableType]
  );
  const columns = useColumns(HelpmenuFields, 0);
  const initialColumnVisibilitySettings = {
    video: true,
    title: true,
    description: true,
    categories: true,
    actions: false,
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const localStorageValue = localStorage.getItem(getLocalStorageColumnsStatusKey());
    if (localStorageValue) {
      return JSON.parse(localStorageValue);
    }
    return initialColumnVisibilitySettings;
  });
  const [columnsOrders, setColumnsOrders] = useState(
    JSON.parse(localStorage.getItem(getLocalStorageColumnsOrders()) || '{}')
  );
  const [currentPage, setCurrentPage] = useState(state.currentPage);

  const onColumnVisibilityModelChange = (model: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(model);
    localStorage.setItem(getLocalStorageColumnsStatusKey(), JSON.stringify(model));
  };
  const onColumnOrderChange = useCallback(
    (params: GridColumnOrderChangeParams, event: MuiEvent, details: any) => {
      const { field, targetIndex } = params; // Destructure column Name // and it's target Index
      if (columnsOrders[field] === targetIndex) return;
      setColumnsOrders((prevState: any) => ({
        ...prevState,
        [field]: targetIndex,
      }));
      localStorage.setItem(getLocalStorageColumnsOrders(), JSON.stringify(columnsOrders));
    },
    [columnsOrders, getLocalStorageColumnsOrders]
  );
  useEffect(() => {
    Object.entries(columnsOrders).forEach(([key, value]) => {
      const actualIndex = apiRef.current.getColumnIndex(key);
      if (actualIndex !== value) apiRef.current.setColumnIndex(key, value as number);
    });
  }, [columnsOrders]);

  const onPageSizeChange = (pageSize: number) => {
    const searchParam = {
      page: state.currentPage,
      size: pageSize,
    };
    dispatch(changeSearchParamsAction(searchParam));
    dispatch(changePageSizeAction(pageSize));
    setCurrentPage(0);
    dispatch(changePageAction(0));
    dispatch(gethelpMenuAPIAction(!parentAPiCall));
  };

  const onPageChange = (page: number) => {
    const searchParam = {
      page,
      size: state.pageSize,
    };
    dispatch(changeSearchParamsAction(searchParam));
    setCurrentPage(page);
    dispatch(changePageAction(page));
    dispatch(gethelpMenuAPIAction(!parentAPiCall));
  };

  const onSelectionModelChange = (selectionModel: GridSelectionModel, details: GridCallbackDetails) => {
    const videoIds = state?.datas?.map((video) => video.id) || [];
    const unselected = difference(videoIds, selectionModel);
    const oldUnselected = state.excludedVideos.filter(
      (excludedVideo) => !selectionModel.includes(excludedVideo) && !videoIds.includes(Number(excludedVideo))
    );
    const everyUnselected = [...oldUnselected, ...unselected];
    dispatch(excludeVideosAction(everyUnselected));
    dispatch(selectVideosAction(selectionModel));
  };

  const onSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    dispatch(sortHomeMenuVideoAction(sortModel));
  }, []);

  //   const getRowClassName = useCallback((params: GridRowClassNameParams) => {
  //     if (params.row.label && params.row.label.includes('cancelled')) return 'Mui-disabled';
  //     return params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd';
  //   }, []);

  const dataTableProps: Omit<DataGridProProps, 'columns'> = {
    rows: data ?? [],
    initialState: {
      columns: {
        orderedFields: columnsOrders,
      },
    },
    onColumnVisibilityModelChange,
    columnVisibilityModel,
    apiRef,
    autoHeight: true,
    pagination: true,
    disableColumnMenu: true,
    headerHeight: 100,
    rowHeight: 80,
    onPageSizeChange,
    pageSize: state.pageSize,
    rowsPerPageOptions: [10, 25, 50, 100, 200],
    page: currentPage,
    paginationMode: 'server',
    sortingMode: 'server',
    keepNonExistentRowsSelected: true,
    disableSelectionOnClick: true,
    rowCount: state.totalItems,
    // selectionModel: state.selectedDeliverablesModel,
    onPageChange,
    onSortModelChange,
    onSelectionModelChange,
    // getRowClassName,
    onColumnOrderChange,
    // checkboxSelection: !state.selectedAllDeliverables, // TODO ignore unselect or select after a select all
    checkboxSelection: false, // TODO ignore unselect or select after a select all
  };
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(((loadings?.table ?? 0) > 0 && (loadings?.api ?? 0) > 0) || state.helpMenu === null);
  }, [isLoading, loadings?.api, loadings?.table, state.helpMenu]);
  const [filterButtonEl, setFilterButtonEl] = React.useState<HTMLButtonElement | null>(null);
  return (
    <>
      {[HelpMenuTableType.VIDEOS, HelpMenuTableType.USER_GUIDE].map((tableType) => (
        <div key={tableType}>
          {state.currentTableType === tableType && (
            <DataGrid
              columns={columns[tableType]}
              components={{
                Toolbar: CustomToolbar,
                LoadingOverlay: LinearProgress,
              }}
              componentsProps={{
                loadingOverlay: { style: { zIndex: 9999 } },
                panel: {
                  anchorEl: filterButtonEl,
                  placement: 'bottom-end',
                },
                toolbar: {
                  setFilterButtonEl,
                },
              }}
              loading={isLoading}
              className={state.selectedAllVideos ? 'test' : ''}
              {...dataTableProps}
              sx={{
                '& .Mui-disabled': {
                  background: '#e1e5ef',
                  opacity: '.9',
                  pointerEvents: 'initial',
                },
                overflow: 'auto',
                mb: { xs: 30, md: 10 },
                ...(state.selectedAllVideos && {
                  '& .MuiDataGrid-cellCheckbox .MuiCheckbox-root.Mui-checked': {
                    color: '#ff0000',
                  },
                  '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root.Mui-checked': {
                    color: '#ff0000',
                  },
                  '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root.MuiCheckbox-indeterminate': {
                    color: '#ff0000',
                  },
                }),
              }}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default HelpMenuTable;
