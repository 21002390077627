import { LoadingButton } from '@mui/lab';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { remove } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cancelSelectByHand } from 'store/actions/deliverableActions';
import { closeDialogAction } from 'store/actions/dialogsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';

interface CatalogDialogDeleteProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
}

export const CatalogDialogDelete: React.FC<CatalogDialogDeleteProps> = ({ open, onClose, onConfirm, loading }) => {
  const [t] = useTranslation();
  const { state, dispatch } = useAppState();

  const activeItem = state.dialogs.data?.activeItem;

  // useEffect(() => {
  //   console.log('CatalogDialogDelete mounted');
  //   return () => console.log('CatalogDialogDelete unmounted');
  // }, []);

  // const handleClose = () => {
  //   dispatch(closeDialogAction('catalogDialogDelete'));
  // };

  // const handleConfirm = async () => {
  //   if (state.dialogs.data?.confirm) {
  //     await state.dialogs.data.confirm();
  //   }
  //   handleClose();
  // };

  return (
    <>
      <Dialog style={{ maxHeight: 435 }} maxWidth="md" open={open} onClose={onClose}>
        <DialogTitle>{t('Catalog Deletion')}</DialogTitle>
        <DialogContent>
          <div>{t('Are you sure to delete the catalog ?.')}</div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={onClose} disabled={loading}>
            {t('Cancel')}
          </Button>
          <LoadingButton variant="contained" loading={loading} onClick={onConfirm} color="primary">
            {t('OK')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CatalogDialogDelete;
