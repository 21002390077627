import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import useResourcesRights from 'hooks/rights/useResourcesRights';
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { HelpMenuTableType } from '../../../types/enums/HelpMenuTableType';
import { SelectChangeEvent } from '@mui/material/Select';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { MultiSelectUserGuide } from 'components/';
import { useForm, Controller } from 'react-hook-form';
import { helpRoleAction, selectGuideModelFlagAction, updateGuideRowAction } from 'store/actions/helpmenuActions';
import useHelpMenuService from 'features/HelpMenu/hooks/useHelpMenuService';

import Role from 'types/entities/Role';
import Loader from '../../../components/Loader';

interface guideData {
  title: string;
  description: string;
  role: number[];
  guideFile: string;
  guideFileName: File | null;
  fileSize: number;
}

interface HelpMenuHeaderProps {
  userRoles: any;
  getHelpMenuList: () => Promise<void>; // Adjust type if necessary
}

const UserGuideHeader: React.FC<HelpMenuHeaderProps> = ({ userRoles, getHelpMenuList }) => {
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const { helpMenu } = useSelector((state) => state);
  const loadings = useSelector((state) => state.loadings);
  const resourceRight = useResourcesRights();
  const { uploadGuidePdf, updateGuide, getRoles } = useHelpMenuService();
  const [guideId, setguideId] = useState<number | undefined>(undefined);
  const [selectedRoles, setselectedRoles] = useState<Role[]>([]);
  const [guideData, setguideData] = useState<guideData>({
    title: '',
    description: '',
    role: [],
    guideFile: '',
    guideFileName: null,
    fileSize: 0,
  });

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleGuideRoleChange = (newRoles: Role[]) => {
    setselectedRoles(newRoles);
    const newRoleNames = newRoles.map((item) => item.id);

    setguideData((prevState) => ({
      ...prevState,
      role: newRoleNames, // Update categories all at once
    }));
  };

  const handleRoleAdded = (newRole: Role) => {
    console.log('New Role Added:', newRole);
    // Optionally update the categories list here
  };

  const handleRoleArchived = (Role: Role) => {
    console.log('Role Archived:', Role);
    // Optionally handle archiving here
  };

  const handleRoleDeletionAttempt = () => {
    console.log('Attempt to delete a Role');
    // Optionally show a confirmation dialog or handle deletion attempt logic
  };

  const getRolesList = useCallback(async () => {
    let datas = await getRoles();
    datas = datas.filter((item: { name: string }) => item.name !== 'admin');
    dispatch(helpRoleAction({ datas }));
  }, []);

  useEffect(() => {
    if (userRoles?.isAdmin) getRolesList();
  }, [userRoles]);

  useEffect(() => {
    if (helpMenu?.updateSelectedGuideModel?.length === 0) {
      setselectedRoles([]);
      setguideData({ title: '', description: '', role: [], guideFile: '', guideFileName: null, fileSize: 0 });
    }
  }, [helpMenu?.updateSelectedGuideModel]);

  useEffect(() => {
    if (helpMenu?.updateSelectedGuideModel?.id && helpMenu?.currentTableType !== 'Videos') {
      const editGuide = helpMenu?.updateSelectedGuideModel;
      const roleIds: number[] = editGuide?.roles?.map((item: any) => item.id);
      const commonRoles = helpMenu?.roles?.filter((role) => roleIds?.includes(role.id)) || [];
      setselectedRoles(commonRoles);
      setguideId(editGuide?.id);
      setValue('title', editGuide?.title);
      setguideData({
        title: editGuide?.title,
        description: editGuide?.description,
        role: roleIds,
        guideFile: 'guide.pdf',
        guideFileName: null,
        fileSize: 0,
      });
    }
  }, [helpMenu?.updateSelectedGuideModel]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setguideData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Corrected onChange handler for the Select component
  const handleRoleChange = (e: SelectChangeEvent<string>) => {
    // setguideData((prevState) => ({
    //   ...prevState,
    //   Role: e.target.value, // Access Role value correctly
    // }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file && file.type === 'application/pdf') {
      // Create a new FileReader instance
      const reader = new FileReader();
      // Error handling for the reader
      reader.onerror = () => {
        console.error('Error reading file:', reader.error);
        alert('There was an error reading the file. Please try again.');
      };
      reader.onloadend = () => {
        // Check if the result is a string
        if (typeof reader.result === 'string') {
          // Once the file is read, get the base64 string and remove the data URL prefix
          const base64File = reader.result.split(',')[1]; // Remove the data URL prefix
          const pdfSize = file.size / (1024 * 1024);
          // Update the state with the base64 string
          setguideData((prevState) => ({
            ...prevState,
            guideFile: base64File,
            guideFileName: file,
            fileSize: pdfSize,
          }));
        } else {
          console.error('Failed to read file as base64, reader.result is not a string.');
        }
      };

      // Read the file as a data URL (base64)
      reader.readAsDataURL(file);
    } else {
      console.error('Selected file is not a valid PDF.');
    }
  };

  const handleSave = async () => {
    // Handle the save logic here (e.g., send data to the server)
    dispatch(addLoadingAction('adminGuideEditList'));
    if (guideId !== undefined) {
      const guidePayload = {
        title: guideData?.title,
        description: guideData?.description,
        roleId: guideData?.role,
      };
      const pdfName = guideData?.guideFileName === null ? '' : guideData?.guideFile;
      const datas = await updateGuide(guideId, pdfName, guidePayload);
      if (datas === 'User Guide updated successfully') {
        getHelpMenuList();
      }

      dispatch(selectGuideModelFlagAction(false));
      dispatch(updateGuideRowAction([]));
      setguideId(undefined);
      reset();
      dispatch(removeLoadingAction('adminGuideEditList'));
    } else {
      const guidePayload = {
        title: guideData?.title,
        description: guideData?.description,
        roleId: guideData?.role,
      };
      const datas = await uploadGuidePdf(guideData?.guideFileName, guidePayload);
      if (datas === 'User Guide created successfully') {
        getHelpMenuList();
      }
      dispatch(selectGuideModelFlagAction(false));
      dispatch(updateGuideRowAction([]));
      setguideId(undefined);
      reset();
      dispatch(removeLoadingAction('adminGuideEditList'));
    }
  };

  const handleCancel = () => {
    dispatch(selectGuideModelFlagAction(false));
    dispatch(updateGuideRowAction([]));
    setguideId(undefined);
  };

  const isValidGuideData = () =>
    guideData?.title?.trim() === '' ||
    guideData?.description?.trim() === '' ||
    guideData?.role?.length === 0 ||
    guideData.guideFile?.trim() === '' ||
    (guideData.fileSize > 0 && parseFloat(guideData.fileSize.toFixed(2)) >= 10);

  return (
    <>
      {loadings?.adminGuideEditList ? (
        <Loader className="loader_container_saveGuide" />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: { md: 'center' },
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {/* Upload Guide Button */}
          {helpMenu.currentTab === HelpMenuTableType.USER_GUIDE && resourceRight.canUploadUserGuide && (
            <Button
              variant="contained"
              onClick={() => dispatch(selectGuideModelFlagAction(true))} // Open the modal
              sx={{ mb: { xs: 3, md: 0 } }}
            >
              {t('Upload File')}
            </Button>
          )}
          <form onSubmit={handleSubmit(handleSave)}>
            {/* Modal for Uploading Guide */}
            <Dialog open={helpMenu?.selectGuideModelFlag} onClose={handleCancel}>
              <DialogTitle>{t('Upload File')}</DialogTitle>
              <DialogContent>
                {/* Title */}
                <Controller
                  name="title"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <TextField
                        // {...field} // Spread react-hook-form's field props
                        label={t('Title')}
                        name="title"
                        // value={videoData.title}
                        value={value || guideData?.title}
                        // onChange={handleChange}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(e.target.value); // Use the onChange from Controller to update the value
                          handleChange(e); // Optionally handle any other custom logic with your own handleChange
                        }}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                        inputProps={{ maxLength: 100 }}
                        error={!!errors.title} // Error state will be true if the validation fails
                        helperText={errors.title ? errors.title.message : ''} // Display error message if present
                      />
                    </>
                  )}
                  rules={{
                    required: 'Title is required', // Error message if field is empty
                    pattern: {
                      value: /^[a-zA-Z0-9\s\-'(),.&]+$/i, // Pattern for valid characters (no special chars)
                      message: 'Special characters are not allowed', // Error message for invalid characters
                    },
                  }}
                />

                {/* <TextField
                label={t('Title')}
                name="title"
                value={guideData?.title}
                onChange={handleChange}
                fullWidth
                required
                sx={{ mb: 2 }}
                inputProps={{ maxLength: 100 }}
              /> */}
                {/* Description */}
                <TextField
                  label={t('Description')}
                  name="description"
                  value={guideData?.description}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  required
                  rows={4}
                  sx={{ mb: 2 }}
                  inputProps={{ maxLength: 5000 }}
                />
                {/* Role */}

                <MultiSelectUserGuide
                  items={helpMenu?.roles && helpMenu?.roles?.length > 0 ? helpMenu?.roles : []}
                  value={selectedRoles}
                  onChange={handleGuideRoleChange}
                  selectAllLabel="Select Access by"
                  onRoleAdded={handleRoleAdded}
                  onRoleArchived={handleRoleArchived}
                  onRoleDeletionAttempt={handleRoleDeletionAttempt}
                  maximumVisible={3}
                />

                {/* Upload Guide File with Custom Button Text */}
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                  <Button variant="outlined" component="label" sx={{ mr: 2 }}>
                    {t('Upload File *')}
                    <input type="file" name="guideFile" accept=".pdf" required onChange={handleFileChange} hidden />
                  </Button>

                  {/* Display selected Guide file name and size */}
                  {guideData?.guideFile?.length > 0 && (
                    <>
                      <PictureAsPdfIcon />
                      {guideData.guideFileName && (
                        <Typography variant="body2" sx={{ ml: 2 }}>
                          ({(guideData.guideFileName.size / (1024 * 1024)).toFixed(2)} MB)
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
                {guideData.fileSize > 0 && parseFloat(guideData.fileSize.toFixed(2)) >= 10 && (
                  <Typography data-testid="error_reported" sx={{ color: 'error.main', mt: 2 }}>
                    Please upload less than 10 mb
                  </Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="secondary">
                  {t('Cancel')}
                </Button>
                <Button
                  disabled={isValidGuideData()}
                  onClick={handleSubmit(handleSave)}
                  color="primary"
                  variant="contained"
                >
                  {guideId ? t('Update') : t('Save')}
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </Box>
      )}
    </>
  );
};

export default UserGuideHeader;
