import { GridRowId, GridSelectionModel, GridSortModel, GridRowModel } from '@mui/x-data-grid-pro';
import { HelpMenuTableType } from 'types/enums/HelpMenuTableType';
import HelpMenuListVideos from 'types/entities/HelpMenuVideos';
import Categories from 'types/entities/Categories';
import HelpMenuUserGuide from 'types/entities/HelpMenuUserGuide';
import Role from 'types/entities/Role';
import { SearchParams } from 'api/ResourceAPI';

export const HelpMenuActions = {
  CALL_HELPMENU_FUNCTION: 'CALL_HELPMENU_FUNCTION',
  LOAD_HELPMENU: 'LOAD_HELPMENU ',
  CHANGE_CURRENT_TAB_HELPMENU: 'CHANGE_CURRENT_TAB_HELPMENU',
  HELPMENU_VIDEOS: 'HELPMENU_VIDEOS',
  SORT_HELP_MENU_VIDEOS: 'SORT_HELP_MENU_VIDEOS',
  CHANGE_PAGE: 'CHANGE_PAGE',
  CHANGE_PAGE_SIZE: 'CHANGE_PAGE_SIZE',
  SELECT_ALL_VIDEOS: 'SELECT_ALL_VIDEOS',
  GET_CATALOGUE_LIST: 'GET_CATALOGUE_LIST',
  SELECT_VIDEO: 'SELECT_VIDEO',
  SELECT_VIDEOS: 'SELECT_VIDEOS',
  DELETE_VIDEO: 'DELETE_VIDEO',
  CHANGE_SELECTED_VIDEOS_COUNT: 'CHANGE_SELECTED_VIDEOS_COUNT',
  EXCLUDE_VIDEOS_COUNT: 'EXCLUDE_VIDEOS_COUNT',
  SELECT_VIDEO_MODEL_FLAG: 'SELECT_VIDEO_MODEL_FLAG',
  UPDATE_VIDEO_ROW_MODEL: 'UPDATE_VIDEO_ROW_MODEL',

  SELECT_GUIDE_MODEL_FLAG: 'SELECT_GUIDE_MODEL_FLAG',
  GET_GUIDE_LIST: 'GET_GUIDE_LIST',
  UPDATE_GUIDE_ROW_MODEL: 'UPDATE_GUIDE_ROW_MODEL',
  SELECT_GUIDE: 'SELECT_GUIDE',
  SELECT_GUIDES: 'SELECT_GUIDES',
  HELPMENU_GUIDES: 'HELPMENU_GUIDES',
  CHANGE_SEARCH_PARAMS_USERGUIDE: 'CHANGE_SEARCH_PARAMS_USERGUIDE',
} as const;

export const gethelpMenuAPIAction = (payload: boolean) => ({
  type: HelpMenuActions.CALL_HELPMENU_FUNCTION,
  payload,
});

export const loadHelpMenuAction = (payload: string[]) => ({
  type: HelpMenuActions.LOAD_HELPMENU,
  payload,
});

export const changeCurrentTabAction = (payload: HelpMenuTableType) => ({
  type: HelpMenuActions.CHANGE_CURRENT_TAB_HELPMENU,
  payload,
});

export const sortHomeMenuVideoAction = (payload: GridSortModel) => ({
  type: HelpMenuActions.SORT_HELP_MENU_VIDEOS,
  payload,
});

export const helpMenuListAction = (payload: {
  totalItems: number;
  datas: HelpMenuListVideos[] | null;
  totalPages: number;
}) => ({
  type: HelpMenuActions.HELPMENU_VIDEOS,
  payload,
});

export const changeSearchParamsAction = (payload: SearchParams | null) => ({
  type: HelpMenuActions.CHANGE_SEARCH_PARAMS_USERGUIDE,
  payload,
});

export const changePageAction = (payload: number) => ({
  type: HelpMenuActions.CHANGE_PAGE,
  payload,
});

export const changePageSizeAction = (payload: number) => ({
  type: HelpMenuActions.CHANGE_PAGE_SIZE,
  payload,
});

export const selectAllVideoAction = (payload: boolean) => ({
  type: HelpMenuActions.SELECT_ALL_VIDEOS,
  payload,
});

export const helpCatelogueAction = (payload: { datas: Categories[] | null }) => ({
  type: HelpMenuActions.GET_CATALOGUE_LIST,
  payload,
});

export const selectVideoAction = (payload: HelpMenuListVideos['id'] | null) => ({
  type: HelpMenuActions.SELECT_VIDEO,
  payload,
});

export const selectVideosAction = (payload: GridSelectionModel) => ({
  type: HelpMenuActions.SELECT_VIDEOS,
  payload,
});

export const updateVideoRowAction = (payload: GridRowModel) => ({
  type: HelpMenuActions.UPDATE_VIDEO_ROW_MODEL,
  payload,
});

export const selectVideoModelFlagAction = (payload: boolean) => ({
  type: HelpMenuActions.SELECT_VIDEO_MODEL_FLAG,
  payload,
});

export const deleteVideoAction = (payload: HelpMenuListVideos['id'] | GridRowId) => ({
  type: HelpMenuActions.DELETE_VIDEO,
  payload,
});

export const changeSelectedVideosCountAction = (payload: number) => ({
  type: HelpMenuActions.CHANGE_SELECTED_VIDEOS_COUNT,
  payload,
});

export const excludeVideosAction = (payload: GridRowId[]) => ({
  type: HelpMenuActions.EXCLUDE_VIDEOS_COUNT,
  payload,
});

// USER GUIDE

export const helpMenuGuideListAction = (payload: {
  totalItems: number;
  datas: HelpMenuUserGuide[] | null;
  totalPages: number;
}) => ({
  type: HelpMenuActions.HELPMENU_GUIDES,
  payload,
});

export const selectGuideModelFlagAction = (payload: boolean) => ({
  type: HelpMenuActions.SELECT_GUIDE_MODEL_FLAG,
  payload,
});

export const helpRoleAction = (payload: { datas: Role[] | null }) => ({
  type: HelpMenuActions.GET_GUIDE_LIST,
  payload,
});

export const updateGuideRowAction = (payload: GridRowModel) => ({
  type: HelpMenuActions.UPDATE_GUIDE_ROW_MODEL,
  payload,
});

export const selectGuideAction = (payload: HelpMenuUserGuide['id'] | null) => ({
  type: HelpMenuActions.SELECT_GUIDE,
  payload,
});

export const selectGuidesAction = (payload: GridSelectionModel) => ({
  type: HelpMenuActions.SELECT_GUIDES,
  payload,
});

export const helpmenuActions = {
  gethelpMenuAPIAction,
  loadHelpMenuAction,
  changeCurrentTabAction,
  sortHomeMenuVideoAction,
  helpMenuListAction,
  changeSearchParamsAction,
  changePageAction,
  changePageSizeAction,
  selectAllVideoAction,
  helpCatelogueAction,
  selectVideoAction,
  selectVideosAction,
  deleteVideoAction,
  changeSelectedVideosCountAction,
  excludeVideosAction,
  selectVideoModelFlagAction,
  updateVideoRowAction,
  selectGuideModelFlagAction,
  helpRoleAction,
  updateGuideRowAction,
  selectGuideAction,
  selectGuidesAction,
  helpMenuGuideListAction,
};
