import { Typography, Tooltip } from '@mui/material';
import React from 'react';
import { useAppState } from 'store/Provider';
import HelpMenuUserGuide from 'types/entities/HelpMenuUserGuide';

const TitleCell = React.memo((row: HelpMenuUserGuide) => {
  const { dispatch } = useAppState();
  return (
    <Tooltip arrow title={row.title ?? ''}>
      <Typography noWrap variant="body2">
        {row?.title}
      </Typography>
    </Tooltip>
  );
});

export default TitleCell;
