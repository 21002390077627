import { TabContext, TabPanel } from '@mui/lab';
import { Layout } from 'components';
import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useUserRoles from 'hooks/useUserRoles';
import { useAppState } from '../../store/Provider';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import HelpMenuVideos from './HelpMenuVideos/HelpMenuVideos';
import HelpMenuAdminVideos from './HelpMenuVideos/HelpMenuAdminVideos';
import UserGuideAdmin from './HelpMenuUserGuide/UserGuideAdmin';
import HelpMenuUserGuide from './HelpMenuUserGuide/HelpMenuUserGuide';
import { HelpMenuTableType } from 'types/enums/HelpMenuTableType';
import HelpMenuVideoDialogDelete from './HelpMenuDialogDelete/HelpMenuVideoDialogDelete';
import HelpMenuGuideDialogDelete from './HelpMenuDialogDelete/HelpMenuGuideDialogDelete';
import useHelpMenuService from 'features/HelpMenu/hooks/useHelpMenuService';
import { helpMenuGuideListAction, helpMenuListAction } from 'store/actions/helpmenuActions';
import HelpMenuTabs from './HelpMenuTabs/HelpMenuTabs';
import HelpMenuVideoHeader from './HelpMenuHeader/HelpMenuVideoHeader';
import UserGuideHeader from './HelpMenuHeader/UserGuideHeader';

const HelpMenu: React.FC<RouteComponentProps> = ({ match }) => {
  const { state, dispatch } = useAppState();
  const userRoles = useUserRoles();
  const [isLoading, setIsLoading] = useState(true);
  const userRole = state.app.roles ?? [];
  const [role, setRole] = useState(false);
  const { helpMenu } = state;

  const { getHelpmenuVideoList, getHelpmenuGuideList, getHelpmenuUserGuideList } = useHelpMenuService();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setRole(userRoles?.isAdmin);
      setIsLoading(false);
    }, 1000); // Timeout set for 3 seconds

    // Cleanup function to clear the timeout when the component unmounts or when the effect is re-run
    return () => clearTimeout(timeoutId);
  }, [userRoles]);

  const getHelpMenuList = async () => {
    dispatch(addLoadingAction('adminvideoList'));
    if (helpMenu?.currentTableType === 'Videos') {
      const { totalItems, datas, totalPages } = await getHelpmenuVideoList(helpMenu.currentPage, helpMenu.pageSize);
      dispatch(helpMenuListAction({ totalItems, datas, totalPages }));
      dispatch(removeLoadingAction('adminvideoList'));
    } else if (helpMenu?.currentTableType === 'User Guide' && userRole[0] === 'admin') {
      const { totalItems, datas, totalPages } = await getHelpmenuGuideList(helpMenu.currentPage, helpMenu.pageSize);
      dispatch(helpMenuGuideListAction({ totalItems, datas, totalPages }));
      dispatch(removeLoadingAction('adminvideoList'));
    } else {
      const { totalItems, datas, totalPages } = await getHelpmenuUserGuideList(
        helpMenu.currentPage,
        helpMenu.pageSize,
        userRole[0]
      );
      dispatch(helpMenuGuideListAction({ totalItems, datas, totalPages }));
      dispatch(removeLoadingAction('adminvideoList'));
    }
  };

  useEffect(() => {
    getHelpMenuList();
  }, [state.helpMenu?.helpMenuApiFlag]);

  return (
    <Layout name="Learning Resources" path="/helpmenu">
      <TabContext value={helpMenu.currentTab}>
        <HelpMenuTabs />
        <HelpMenuVideoHeader userRoles={userRoles} getHelpMenuList={getHelpMenuList} />
        <UserGuideHeader userRoles={userRoles} getHelpMenuList={getHelpMenuList} />
        <TabPanel value={HelpMenuTableType.VIDEOS}>
          {isLoading ? <> </> : <>{role ? <HelpMenuAdminVideos /> : <HelpMenuVideos />}</>}
        </TabPanel>
        <TabPanel value={HelpMenuTableType.USER_GUIDE}>
          {isLoading ? <> </> : <>{role ? <UserGuideAdmin /> : <HelpMenuUserGuide />}</>}
        </TabPanel>
      </TabContext>
      <HelpMenuVideoDialogDelete getHelpMenuList={getHelpMenuList} />
      <HelpMenuGuideDialogDelete getHelpMenuList={getHelpMenuList} />
    </Layout>
  );
};

export default HelpMenu;
