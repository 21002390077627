import { Avatar, AvatarGroup, Box, Button, CardContent, Grid, Stack, Tooltip, Typography } from '@mui/material';
import ProtectedLink from 'components/ProtectedLink';
import { trim } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { capitalize, stringToColor } from '../../../helpers/format';
import useManagementRights from '../../../hooks/rights/useManagementRights';
import useUserRoles from '../../../hooks/useUserRoles';
import { useSelector } from '../../../store/hooks';
import OrderMenu from '../OrderMenu/OrderMenu';
import { OrderCardProps } from './OrderCard';
import useOrderCardService from './useOrderCardService';

const OrderValidatedCardContent: React.FC<OrderCardProps> = ({
  order,
  action = true,
  menu = true,
  showLink = true,
}) => {
  const [t] = useTranslation();
  const history = useHistory();
  const { app } = useSelector((state) => state);
  const managementRights = useManagementRights(order);
  const userRoles = useUserRoles();
  const { engagementDate, orderConsultantTooltipTitle, orderClient } = useOrderCardService(order);
  const pathname = `/${app.customer?.slug}/orders/workunits/affectation/order/${order?.id}`;

  return (
    <CardContent sx={{ position: 'relative' }}>
      <Grid item direction="row" xs={12} md={6} lg={6} sx={{ display: 'flex', pb: 1 }} justifyContent="space-between">
        <Grid item xs={12} md={6} lg={6}>
          {showLink ? (
            <ProtectedLink pathname={pathname} right={managementRights.canAffectationOrder}>
              <Tooltip title={trim([order.name_spec, order.ref_spec].join(' - ')) ?? t<string>('Not_defined')}>
                <div>
                  <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                    ID {order?.id} {order?.name_spec}
                  </Typography>
                </div>
              </Tooltip>
            </ProtectedLink>
          ) : (
            <Tooltip title={trim([order.name_spec, order.ref_spec].join(' - ')) ?? t<string>('Not_defined')}>
              <div>
                <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                  ID {order?.id} {order?.name_spec}
                </Typography>
              </div>
            </Tooltip>
          )}
          <Typography>{t('Project_name')}</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Stack spacing={2} direction="row">
            {menu && <OrderMenu order={order} />}
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="flex-start">
        <Grid item xs={12} md={6} lg={!userRoles.isConsultant && !userRoles.isCustomer ? 3 : 4}>
          {order.affair ? (
            <Tooltip title={trim([order.affair?.otp, order.affair?.name].join(' - '))}>
              <div>
                <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                  {order.affair?.name}
                </Typography>
                <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                  {order.affair?.otp}
                </Typography>
              </div>
            </Tooltip>
          ) : (
            <div>
              <Typography noWrap>{t('Affair_not_indicated')} </Typography>
              <Box sx={{ height: '1.6rem' }} />
            </div>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={!userRoles.isConsultant && !userRoles.isCustomer ? 3 : 4}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Tooltip title={orderClient()}>
              <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                {orderClient()}
              </Typography>
            </Tooltip>
            <Typography noWrap>{t('Client')}</Typography>
          </Box>
        </Grid>

        {!userRoles.isConsultant && !userRoles.isCustomer && (
          <Grid item xs={12} md={6} lg={2}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Tooltip placement="bottom-start" title={orderConsultantTooltipTitle}>
                <Box sx={{ minHeight: '24px', display: 'flex', alignItems: 'center' }}>
                  {order.consultants && order.consultants[0] ? (
                    <AvatarGroup max={3}>
                      {order.consultants.map((c) =>
                        c.avatar_uri ? (
                          <Avatar src={c.avatar_uri} key={c.id} />
                        ) : (
                          <Avatar
                            sx={{
                              backgroundColor: stringToColor(c.first_name),
                              color: stringToColor(c.first_name, 'color'),
                              width: 28,
                              height: 28,
                            }}
                            key={c.id}
                          >{`${capitalize(c.first_name.charAt(0))}${capitalize(c.last_name.charAt(0))}`}</Avatar>
                        )
                      )}
                    </AvatarGroup>
                  ) : (
                    <Typography sx={{ color: 'primary.main', fontWeight: 500 }}>-</Typography>
                  )}
                </Box>
              </Tooltip>
              <Typography>{t('Consultants')}</Typography>
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={6} lg={3}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mt: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Tooltip title={engagementDate()}>
                <Typography noWrap sx={{ color: 'primary.main', fontWeight: 500 }}>
                  {engagementDate()}
                </Typography>
              </Tooltip>
              <Typography>{t('Engagement_date')}</Typography>
            </Box>

            {action && managementRights.canAffectationOrder && (
              <Box sx={{ ml: 2 }}>
                <Button variant="contained" onClick={() => history.push(pathname)}>
                  {t('Assign')}
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default OrderValidatedCardContent;
