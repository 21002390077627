import { GridRowId, GridSelectionModel, GridSortModel, GridRowModel } from '@mui/x-data-grid-pro';
import { HelpMenuTableType } from '../../types/enums/HelpMenuTableType';
import HelpMenuListVideos from 'types/entities/HelpMenuVideos';
import HelpMenuUserGuide from 'types/entities/HelpMenuUserGuide';
import Categories from 'types/entities/Categories';
import Role from 'types/entities/Role';
import { SearchParams } from 'api/ResourceAPI';

export interface HelpMenuState {
  helpMenuApiFlag: boolean;
  helpMenu: string[];
  datas: HelpMenuListVideos[] | null;
  guideDatas: HelpMenuUserGuide[] | null;
  activeVideoModel: GridSelectionModel;
  selectedAllVideos: boolean;
  selectVideoModelFlag: boolean;
  selectedVideoCount: number;
  selectedVideoModel: GridSelectionModel;
  updateSelectedVideoModel: GridRowModel;
  excludedVideos: GridRowId[];
  searchParams: SearchParams | null;
  sortHelpMenuModel: GridSortModel;
  totalItems: number;
  totalPages: number;
  currentPage: number;
  pageSize: number;
  currentTab: HelpMenuTableType;
  currentTableType: HelpMenuTableType;
  categories: Categories[] | [];

  activeGuideModel: GridSelectionModel;
  selectedAllGuides: boolean;
  selectGuideModelFlag: boolean;
  selectedGuideCount: number;
  selectedGuideModel: GridSelectionModel;
  updateSelectedGuideModel: GridRowModel;
  excludedGuides: GridRowId[];
  roles: Role[] | null;
}

export const helpmenuState: HelpMenuState = {
  helpMenuApiFlag: false,
  helpMenu: [],
  datas: [],
  guideDatas: [],
  activeVideoModel: [],
  selectedAllVideos: false,
  selectVideoModelFlag: false,
  selectedVideoModel: [],
  updateSelectedVideoModel: [],
  excludedVideos: [],
  selectedVideoCount: 0,
  searchParams: {
    // join: ['title', 'description', 'category'],
    size: 50,
    page: 0,
  },
  sortHelpMenuModel: [],
  totalItems: 0,
  totalPages: 0,
  currentPage: 0,
  pageSize: 50,
  currentTableType: HelpMenuTableType.VIDEOS,
  currentTab: HelpMenuTableType.VIDEOS,
  categories: [],

  activeGuideModel: [],
  selectedAllGuides: false,
  selectGuideModelFlag: false,
  selectedGuideCount: 0,
  selectedGuideModel: [],
  updateSelectedGuideModel: [],
  excludedGuides: [],
  roles: [],
};
