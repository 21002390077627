import { GridCellParams, GridColDef, GridColumnHeaderParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import DescriptionCell from '../HelpMenuTable/Cells/DescriptionCell/DescriptionCell';
import TitleCell from '../HelpMenuTable/Cells/Title/TitleCell';
import VideoCell from '../HelpMenuTable/Cells/VideoCell/VideoCell';
import CategoriesCell from '../HelpMenuTable/Cells/CategoriesCell/CategoriesCell';
import ActionsCell from '../HelpMenuTable/Cells/ActionsCell/ActionsCell';
import AccessbyCell from '../HelpMenuTable/Cells/AccessbyCell/AccessByCell';
import UserGuideActionsCell from '../HelpMenuTable/Cells/UserGuideActionsCell/UserGuideActionsCell';
import UserGuideDescriptionCell from '../HelpMenuTable/Cells/UserGuideDescriptionCell/DescriptionCell';
import UserGuideTitle from '../HelpMenuTable/Cells/UserGuideTitle/TitleCell';
import FileCell from '../HelpMenuTable/Cells/FileCell/FileCell';
import useUserRoles from 'hooks/useUserRoles';
import { find, forEach, map } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import HelpMenuListVideos from 'types/entities/HelpMenuVideos';
import HelpMenuUserGuide from 'types/entities/HelpMenuUserGuide';
import { HelpMenuTableType } from 'types/enums/HelpMenuTableType';
import { useSelector } from 'store/hooks';

type HelpMenuGridCellParams<T> = GridCellParams<T, HelpMenuListVideos, any>;
type HelpMenuGuideGridCellParams<T> = GridCellParams<T, HelpMenuUserGuide, any>;

export const columnField = {
  VIDEO: 'Video',
  TITLE: 'Title',
  DESCRIPTION: 'Description',
  CATEGORIES: 'Categories',
  ACTIONS: 'Actions',
  USERGUIDETITLE: 'Title',
  USERGUIDEDESCRIPTION: 'Description',
  ACCESSBY: 'Access by',
  FILE: 'File',
  USERGUIDEACTIONS: 'Action',
} as const;

const defaultColumnParams: GridColDef = {
  field: '',
  headerName: '',
  align: 'left',
  headerAlign: 'center',
  filterable: false,
  sortable: true,
  flex: 1,
};

export const columnsParams: GridColDef[] = [
  {
    field: columnField.ACTIONS,
    renderCell: (params: HelpMenuGridCellParams<null>) => <ActionsCell {...params.row} />,
    resizable: true,
    align: 'center',
    minWidth: 50,
  },
  {
    field: columnField.VIDEO,
    renderCell: (params: HelpMenuGridCellParams<number>) => <VideoCell {...params.row} />,
    resizable: true,
    align: 'center',
    minWidth: 60,
  },
  {
    field: columnField.TITLE,
    renderCell: (params: HelpMenuGridCellParams<number>) => <TitleCell {...params.row} />,
    resizable: true,
    align: 'center',
    minWidth: 60,
  },
  {
    field: columnField.DESCRIPTION,
    renderCell: (params: HelpMenuGridCellParams<number>) => <DescriptionCell {...params.row} />,
    resizable: true,
    align: 'center',
    minWidth: 60,
  },
  {
    field: columnField.CATEGORIES,
    headerName: 'Catalogues',
    renderCell: (params: HelpMenuGridCellParams<number>) => <CategoriesCell {...params.row} />,
    resizable: true,
    align: 'center',
    minWidth: 60,
  },
  {
    field: columnField.USERGUIDETITLE,
    renderCell: (params: HelpMenuGuideGridCellParams<number>) => <UserGuideTitle {...params.row} />,
    resizable: true,
    align: 'center',
    minWidth: 60,
  },
  {
    field: columnField.USERGUIDEDESCRIPTION,
    renderCell: (params: HelpMenuGuideGridCellParams<number>) => <UserGuideDescriptionCell {...params.row} />,
    resizable: true,
    align: 'center',
    minWidth: 60,
  },
  {
    field: columnField.ACCESSBY,
    headerName: 'Access by',
    renderCell: (params: HelpMenuGuideGridCellParams<number>) => <AccessbyCell {...params.row} />,
    resizable: true,
    align: 'center',
    minWidth: 60,
  },
  {
    field: columnField.FILE,
    headerName: 'File',
    renderCell: (params: HelpMenuGuideGridCellParams<number>) => <FileCell {...params.row} />,
    resizable: true,
    align: 'center',
    minWidth: 60,
  },
  {
    field: columnField.USERGUIDEACTIONS,
    renderCell: (params: HelpMenuGuideGridCellParams<null>) => <UserGuideActionsCell {...params.row} />,
    resizable: true,
    align: 'center',
    minWidth: 50,
  },
];

type ColumnsFields = { [name: string]: string[] };

export const HelpmenuFields: ColumnsFields = {
  [HelpMenuTableType.VIDEOS]: [
    columnField.VIDEO,
    columnField.TITLE,
    columnField.DESCRIPTION,
    columnField.CATEGORIES,
    columnField.ACTIONS,
  ],
  [HelpMenuTableType.USER_GUIDE]: [
    columnField.USERGUIDETITLE,
    columnField.USERGUIDEDESCRIPTION,
    columnField.ACCESSBY,
    columnField.FILE,
    columnField.USERGUIDEACTIONS,
  ],
};

/**
 * React hook to build columns variable for Mui Datagrid with specific components
 */
export const useColumns = (columnsFields: ColumnsFields, order_type_id: number) => {
  const [t] = useTranslation();
  const userRoles = useUserRoles();
  const state = useSelector((state) => state.helpMenu);
  return useMemo(() => {
    const columnsVideo: { [name: string]: GridColDef<HelpMenuListVideos, any, any>[] } = {};
    forEach(columnsFields, (fields, name) => {
      columnsVideo[name] = map(fields, (field) => {
        const columnParam = find(columnsParams, ['field', field]);
        return {
          ...defaultColumnParams,
          ...columnParam,
          ...{ headerName: t(field) },
        };
      });
    });

    const columnsGuide: { [name: string]: GridColDef<HelpMenuUserGuide, any, any>[] } = {};
    forEach(columnsFields, (fields, name) => {
      columnsGuide[name] = map(fields, (field) => {
        const columnParam = find(columnsParams, ['field', field]);
        return {
          ...defaultColumnParams,
          ...columnParam,
          ...{ headerName: t(field) },
        };
      });
    });

    return state.currentTableType === 'User Guide' ? columnsGuide : columnsVideo;
  }, [columnsFields, userRoles, t, state]);
};
