import React from 'react';
import { Box, Tooltip, Stack, Chip } from '@mui/material';
import { borderRadius, color, width } from '@mui/system';

type Option = {
  id: number;
  name: string;
};

interface VideoTagProps {
  items: Option[];
  maxVisible: number;
}

const style = {
  fontSize: 'small',
  backgroundColor: '#ffddf4',
  border: '1px solid #b04175',
  borderRadius: 50,
  '& .MuiChip-label': {
    display: 'block',
    whiteSpace: 'normal',
  },
};

const style_label = {
  fontSize: 'small',
  backgroundColor: '#808080',
  color: '#ffffff',
  borderRadius: '50%',
  '& .MuiChip-label': {
    display: 'block',
    whiteSpace: 'normal',
  },
};

const VideoTag: React.FC<VideoTagProps> = ({ items, maxVisible }) => {
  const visibleItems = items.slice(0, maxVisible);
  const overflowItems = items.slice(maxVisible);

  const tooltipContent = (
    <>
      {overflowItems.map((option) => (
        <Stack key={option.id} direction="column" my={1} spacing={1}>
          <Chip sx={style} label={option.name} />
        </Stack>
      ))}
    </>
  );

  return (
    <Stack direction="row" mt={2} spacing={1}>
      {visibleItems.map((option) => (
        // Only show the first two chips
        <Chip sx={style} key={option.id} label={option.name} />
      ))}
      {overflowItems.length > 0 && (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#fff',
                minHeight: 50, // Set a minimum height for the popover
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
              },
            },
          }}
          disableFocusListener
          disableTouchListener
          title={tooltipContent}
        >
          <Chip sx={style_label} key={overflowItems.toString()} label={`+${overflowItems?.length}`} />
        </Tooltip>
      )}
    </Stack>
  );
};
export default VideoTag;
