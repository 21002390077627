import OrderApiService from 'api/OrderApiService';
import { SearchParams } from 'api/ResourceAPI';
import { base64toBlob } from 'helpers/utils';
import useApi from 'hooks/useApi';
import useUserRoles from 'hooks/useUserRoles';
import { forEach, isEqual } from 'lodash';
import moment from 'moment';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { closeDialogAction, openDialogAction } from 'store/actions/dialogsActions';
import { closeDrawerAction, openDrawerAction } from 'store/actions/drawerActions';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import {
  changeCurrentTabAction,
  changePageAction,
  changeSearchParamsAction,
  deleteOrderAction,
  duplicateOrderAction,
  editManagementOrderAction,
  injectOrdersAction,
  setActiveOrderIdAction,
} from 'store/actions/managementActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import Catalog from 'types/entities/Catalog';
import Order from 'types/entities/Order';
import Scope from 'types/entities/Scope';
import { OrdersTabState } from 'types/enums/OrdersTabState';
import { OrderStatus } from 'types/enums/OrderStatus';
import useManagementHelper from './useManagementHelper';
import { appState } from 'store/states/appState';
import { changeCurrentTab } from 'features/Sheet/SheetContext/_reducers/CurrentTab/CurrentTab';

export default function useManagementService() {
  const { dispatch } = useAppState();
  const { app, management, filter } = useSelector((state) => state);
  const userRoles = useUserRoles();
  const { makeCall } = useApi();
  const { isPathForProduction } = useManagementHelper();
  const history = useHistory();
  const appState = useSelector((state) => state.app);

  const fetchOrdersProduction = useCallback(
    async (searchParams: SearchParams) => {
      if (app.customer?.id) {
        return userRoles.isConsultant
          ? makeCall(OrderApiService.fetchCurrentOrders(app.customer.id, searchParams), '')
          : makeCall(OrderApiService.fetchCurrentOrdersListManagement(app.customer.id, searchParams), '');
      }
      return { orderForCustomer: null, totalPage: 0 };
    },
    [app.customer, makeCall, userRoles.isConsultant]
  );

  const fetchOrdersQuotes = useCallback(
    async (searchQuery: SearchParams) => {
      if (app.customer?.id) {
        return makeCall(OrderApiService.fetchCurrentCustomerOrdersList(app.customer.id, searchQuery), '');
      }
      return { orderForCustomer: null, totalPage: 0 };
    },
    [app.customer, makeCall]
  );

  const fetchOrders = useCallback(async () => {
    try {
      document.getElementById('management_page')?.scrollIntoView({ behavior: 'smooth' });
      dispatch(addLoadingAction('orders'));
      if (!management.searchParams || !management.searchParams.status) {
        return false;
      }

      dispatch(
        injectOrdersAction({
          orders: null,
          totalPages: 0,
          totalItems: 0,
        })
      );
      const { orderForCustomer, totalPage, currentPage } = isPathForProduction()
        ? await fetchOrdersProduction(management.searchParams)
        : await fetchOrdersQuotes(management.searchParams);
      const newPage = currentPage + 1;
      if (orderForCustomer !== null) {
        if (!orderForCustomer.length && totalPage && newPage >= totalPage) {
          dispatch(changePageAction(totalPage - 1));
        } else {
          dispatch(
            injectOrdersAction({
              orders: orderForCustomer,
              totalPages: totalPage,
              totalItems: 0,
              currentPage: newPage,
            })
          );
        }
      }
      dispatch(removeLoadingAction('orders'));
      return orderForCustomer;
    } catch (error) {
      dispatch(setSnackbarAction({ message: (error as Error)?.message, severity: 'error', open: true }));
      return false;
    }
  }, [dispatch, fetchOrdersProduction, fetchOrdersQuotes, isPathForProduction, management.searchParams]);

  const archiveOrder = async (orderId: Order['id']) => {
    await makeCall(OrderApiService.archive(orderId), 'Unable to archive order', `archive_${orderId}`);
    dispatch(deleteOrderAction(orderId));
  };
  const restoreOrder = async (orderId: Order['id']) => {
    await makeCall(OrderApiService.archive(orderId), 'Unable to restore order', `restore_${orderId}`);
    dispatch(deleteOrderAction(orderId));
  };

  const fetchMiniDashboardData = async (
    orderId: Order['id'],
    selectedScope: Scope['id'][],
    { from, to }: { from: string; to: string }
  ) =>
    makeCall(
      OrderApiService.getMiniDashboardData(orderId, selectedScope, from, to),
      'Unable to retrieve data for order',
      `miniDashboard${orderId}`
    );

  const getSortParams = useCallback(() => {
    if (management.pageHandler.currentTab === OrdersTabState.PENDING) {
      return 'pending_at.desc';
    }
    if (management.pageHandler.currentTab === OrdersTabState.DRAFTED) {
      return 'created_at.desc';
    }
    if (management.pageHandler.currentTab === OrdersTabState.TO_ASSIGN) {
      return 'validated_at.desc';
    }
    if (management.pageHandler.currentTab === OrdersTabState.ON_GOING) {
      return 'production_at.desc';
    }
    return 'created_at.desc';
  }, [management.pageHandler.currentTab]);
  const handleManagementSearchParams = useCallback(() => {
    const searchParams: SearchParams = {
      page: management.pageHandler.currentPage - 1,
      sorted: getSortParams(),
      size: 10,
      join: ['client', 'affair'],
    };
    if (filter.selected.text_search?.[0] && filter.selected.text_search?.[0] !== '')
      searchParams['reference,ref_spec,name_spec'] = `:${filter.selected.text_search?.[0]}:`;
    if (
      management.pageHandler.currentTab !== OrdersTabState.ARCHIVED &&
      management.pageHandler.currentTab !== OrdersTabState.MATERIAL_DELETED
    ) {
      searchParams.is_archived = '0';
      searchParams.status = management.pageHandler.currentTab;
    } else if (management.pageHandler.currentTab === OrdersTabState.ARCHIVED) {
      searchParams.is_archived = '1';
      if (isPathForProduction()) {
        searchParams.status = `${OrdersTabState.ON_GOING},${OrdersTabState.TO_ASSIGN},${OrdersTabState.CLOSED}`;
      } else {
        searchParams.status = `${OrdersTabState.DRAFTED},${OrdersTabState.PENDING}`;
      }
    } else if (management.pageHandler.currentTab === OrdersTabState.MATERIAL_DELETED) {
      searchParams.is_archived = '1';
      searchParams.status = `${OrdersTabState.MATERIAL_DRAFT},${OrdersTabState.MATERIAL_PRODUCTION},${OrdersTabState.MATERIAL_CLOSED}`;
    }

    if (filter.selected) {
      const ids: number[][] = [];
      forEach(filter.selected, (values, key) => {
        if (key === 'text_search') return;
        if (values.length && key.includes('date')) {
          if (key === 'date_start' && moment(values[0]).isValid()) {
            // searchParams.start_date = `>=${values[0]}`;
            searchParams.end_date = `>=${values[0]}`;

            if (filter.selected.date_start.length > 0 && filter.selected.date_end.length === 0) {
              searchParams.start_date = `<=${values[0]}`;
            }
          }
          if (key === 'date_end' && moment(values[0]).isValid()) {
            // searchParams.end_date = `<=${values[0]}`;
            searchParams.start_date = `<=${values[0]}`;
          }
        } else if (values.length) {
          const order_ids: string[] = [];
          values.forEach((v) => {
            if (filter.params && filter.params[key] && filter.params[key].length) {
              const found = filter.params?.[key]?.find((property: any) => property.id === parseInt(v, 10))?.order_id;
              if (found) found.forEach((f: string) => order_ids.push(f));
            }
          });
          ids.push(order_ids.map((v) => parseInt(v, 10)));
        }
      });
      if (ids.length) {
        const isIdPresentInEveryOtherArrayIds = (id: number) => ids.every((arrayIds) => arrayIds.includes(id));
        const idsFound: number[] = [];
        ids.forEach((idsArray) => {
          idsArray.forEach((id) => {
            if (isIdPresentInEveryOtherArrayIds(id)) idsFound.push(id);
          });
        });
        if (idsFound.length) {
          searchParams.id = idsFound.join(',');
        } else {
          searchParams.id = -1;
        }
      }
    }
    if (isEqual(searchParams, management.searchParams)) return;
    dispatch(changeSearchParamsAction(searchParams));
  }, [
    management.pageHandler.currentPage,
    management.pageHandler.currentTab,
    management.searchParams,
    getSortParams,
    filter.selected,
    filter.params,
    dispatch,
    isPathForProduction,
  ]);

  const openManagementDuplicationDialog = (orderId: Order['id']) => {
    dispatch(setActiveOrderIdAction(orderId));
    dispatch(openDialogAction({ name: 'managementDuplicationDialog' }));
  };

  const openManagementCloseDialog = (orderId: Order['id']) => {
    dispatch(setActiveOrderIdAction(orderId));
    dispatch(openDialogAction({ name: 'managementCloseDialog' }));
  };

  const openManagementOpenDialog = (orderId: Order['id']) => {
    dispatch(setActiveOrderIdAction(orderId));
    dispatch(openDialogAction({ name: 'managementOpenDialog' }));
  };

  const validateOrder = async (order: Order) => {
    if (!order?.affair_id || !order?.client_id || !order?.draft_reference || !order?.draft_reference) {
      dispatch(setActiveOrderIdAction(order.id));
      dispatch(openDrawerAction({ name: 'CompleteOrderDrawer' }));
      return;
    }

    const {
      affair,
      client,
      discounts,
      price_discounted,
      'order-scopes': oscope,
      catalog,
      'order-workunits': owu,
      ...orderToSend
    } = order;
    await makeCall(
      OrderApiService.update(order.id, {
        ...orderToSend,
        id: order.id,
        discounts,
        status: orderToSend.status === OrderStatus.PENDING ? OrderStatus.VALIDATED : orderToSend.status,
      }),
      'Error while updating order',
      'completeOrderLoading'
    );

    dispatch(closeDrawerAction());
    dispatch(setActiveOrderIdAction(null));
    dispatch(deleteOrderAction(order.id));
  };

  const duplicateOrder = async (orderId: Order['id'] | null) => {
    if (!orderId) return;
    const newOrder: Order = await makeCall(
      OrderApiService.duplicate(orderId),
      'Unable to duplicate order',
      `duplicate_${orderId}}`
    );
    if (management.pageHandler.currentTab === OrdersTabState.DRAFTED) {
      const orderDuplicated = management.orders?.find((o) => o.id === orderId);
      if (!orderDuplicated) return;
      dispatch(duplicateOrderAction({ ...orderDuplicated, ...newOrder }));
      dispatch(setActiveOrderIdAction(null));
    }

    if (
      newOrder.order_type_id === 2 &&
      (management.pageHandler.currentTab === OrdersTabState.MATERIAL_DRAFT ||
        management.pageHandler.currentTab === OrdersTabState.MATERIAL_PRODUCTION ||
        management.pageHandler.currentTab === OrdersTabState.MATERIAL_CLOSED ||
        management.pageHandler.currentTab === OrdersTabState.MATERIAL_DELETED)
    ) {
      const orderDuplicated = management.orders?.find((o) => o.id === orderId);
      if (!orderDuplicated) return;
      dispatch(duplicateOrderAction({ ...orderDuplicated, ...newOrder }));
      dispatch(setActiveOrderIdAction(newOrder.id));
      if (management.pageHandler.currentTab !== OrdersTabState.MATERIAL_DRAFT) {
        dispatch(changeCurrentTabAction(OrdersTabState.MATERIAL_DRAFT));
        history.push(`/${appState.customer?.slug}/orders/production?status=material_draft`);
      }
    }
    dispatch(closeDialogAction('managementDuplicationDialog'));
  };

  const downloadSelectedProductionExcel = async (orderId: Order['id']) => {
    try {
      const excel = await makeCall(OrderApiService.getOrderExcel(orderId));
      const outputFilename = `${excel.filename ? excel.filename : Date.now()}.xlsx`;

      const blob = base64toBlob(excel.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      dispatch(
        setSnackbarAction({ message: 'Error while trying to create file to export', open: true, severity: 'error' })
      );
    }
  };

  const navigateToSummary = (orderId: Order['id']) => {
    history.push(`/${app.customer?.slug}/orders/workunits/summary/${orderId}`);
  };
  const navigateToAffectation = (orderId: Order['id']) => {
    history.push(`/${app.customer?.slug}/orders/workunits/affectation/order/${orderId}`);
  };
  const navigateToOrderCreationWorkunits = (orderId: Order['id'], catalogId: Catalog['id']) => {
    history.push(`/${app.customer?.slug}/catalog/${catalogId}/order/${orderId}`);
  };
  const navigateToDeliverables = (orderId: Order['id']) => {
    history.push(`/${app.customer?.slug}/deliverables/${orderId}`);
  };

  const openEditOrderDrawer = (order: Order) => {
    dispatch(
      openDrawerAction({
        name: 'EditOrderDrawer',
        data: { order, handleUpdateOrder: (order: Order) => editOrder(order) },
      })
    );
  };

  const editOrder = async (order: Order) => {
    const { status, ...orderEdited } = order;
    await makeCall(
      OrderApiService.update(order.id, orderEdited),
      'Error while updating order',
      'updateOrderDrawerEdit'
    );
    dispatch(closeDrawerAction());
    dispatch(editManagementOrderAction({ ...order, ...orderEdited }));
  };

  const openEditTimeMaterialDialog = (order: Order, actionType: string) => {
    dispatch(
      openDialogAction({
        name: 'timeMaterialEditionOrder',
        data: {
          order,
          handleTMUpdateOrder: (order: Order, actionType: string) => editTimeMaterialOrder(order, actionType),
        },
      })
    );
  };

  const editTimeMaterialOrder = async (order: Order, actionType: string) => {
    let updatedOrder = null;
    if (management.pageHandler.currentTab === OrdersTabState.MATERIAL_DRAFT && actionType === 'validate') {
      const { status, ...orderWithoutStatus } = order;
      updatedOrder = { ...orderWithoutStatus, status: OrderStatus.MATERIAL_PRODUCTION };
    } else {
      const { status, ...orderWithoutStatus } = order;
      updatedOrder = orderWithoutStatus;
    }
    await makeCall(
      OrderApiService.update(order.id, updatedOrder),
      'Error while updating order',
      'updateTimeMaterialOrderEdit'
    );
    dispatch(editManagementOrderAction({ ...order, ...updatedOrder }));
    //  console.log('management.pageHandler.currentTab: ', management.pageHandler.currentTab);
    //  console.log('actionType: ', actionType);
    if (management.pageHandler.currentTab === OrdersTabState.MATERIAL_DRAFT && actionType === 'validate') {
      dispatch(changeCurrentTabAction(OrdersTabState.MATERIAL_PRODUCTION));
      history.push(`/${appState.customer?.slug}/orders/production?status=material_production`);
    }
  };

  const closeOrder = async (orderId: Order['id'] | null, cancellation_reason: string | null = null) => {
    if (!orderId) {
      throw new Error('No order id provided');
    }
    const order = management?.orders?.find((o) => o.id === orderId);

    if (!order) {
      throw new Error(`Order ${orderId} not found`);
    }

    if (order.status !== OrderStatus.PRODUCTION && order.status !== OrderStatus.MATERIAL_PRODUCTION) {
      throw new Error(`Order ${orderId} is not in production`);
    }

    const response = await makeCall(
      OrderApiService.update(orderId, {
        status: order.status === OrderStatus.PRODUCTION ? OrderStatus.CLOSED : OrderStatus.MATERIAL_CLOSED,
        is_archived: false,
        cancellation_reason: cancellation_reason ?? undefined,
      }),
      'Error while updating order',
      `close_${orderId}`
    );
    console.log('response: ', response);
    if (response) {
      await fetchOrders();
    }
    return response;
  };

  const openOrder = async (orderId: Order['id'] | null) => {
    if (!orderId) {
      throw new Error('No order id provided');
    }
    const order = management?.orders?.find((o) => o.id === orderId);
    if (!order) {
      throw new Error(`Order ${orderId} not found`);
    }

    if (order.status !== OrderStatus.CLOSED && order.status !== OrderStatus.MATERIAL_CLOSED) {
      throw new Error(`Order ${orderId} is not closed`);
    }
    const response = await makeCall(
      OrderApiService.update(orderId, {
        status: order.status === OrderStatus.MATERIAL_CLOSED ? OrderStatus.MATERIAL_PRODUCTION : OrderStatus.PRODUCTION,
      }),
      'Error while updating order',
      `open_${orderId}`
    );

    if (response) {
      await fetchOrders();
    }
    return response;
  };

  const goBackStatus = useCallback(
    () => (isPathForProduction() ? OrdersTabState.ON_GOING : OrdersTabState.DRAFTED),
    [isPathForProduction]
  );
  return {
    fetchOrders,
    handleManagementSearchParams,
    archiveOrder,
    downloadSelectedProductionExcel,
    restoreOrder,
    navigateToSummary,
    navigateToAffectation,
    navigateToDeliverables,
    duplicateOrder,
    openManagementDuplicationDialog,
    openManagementCloseDialog,
    openManagementOpenDialog,
    validateOrder,
    navigateToOrderCreationWorkunits,
    fetchMiniDashboardData,
    openEditOrderDrawer,
    closeOrder,
    openOrder,
    goBackStatus,
    openEditTimeMaterialDialog,
  };
}
