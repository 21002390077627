/* eslint-disable import/no-extraneous-dependencies */
import { TabContext, TabPanel } from '@mui/lab';
import DeliverableSheetApiService from 'api/DeliverableSheetApiService';
import { handleApiError } from 'api/errors';
import MissionApiService from 'api/MissionApiService';
import orderApiService from 'api/OrderApiService';
import OrderScopesApiService from 'api/OrderScopeApiService';
import { SearchParams } from 'api/ResourceAPI';
import UserApiService from 'api/UserApiService';
import WorkunitApiService from 'api/WorkunitApiService';
import { Layout } from 'components';
import useApi from 'hooks/useApi';
import useCancelApi from 'hooks/useCancelApi';
import useUserRoles from 'hooks/useUserRoles';
import 'moment-timezone';
import React, { useCallback, useEffect, useRef } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import {
  cancelSelectByHand,
  loadConsultantsAction,
  loadCustomersAction,
  loadDeliveryManagersAction,
  loadFrequenciesAction,
  loadOrderScopesAction,
  loadStatusAction,
  loadWorkunitsAction,
  resetDeliverablesAction,
  selectDeliverablesAction,
} from 'store/actions/deliverableActions';
import { loadDeliverableSheetsAction } from 'store/actions/deliverableSheetActions';

import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { DeliverableReportTab } from 'types/enums/DeliverableReportTab';
import { DeliverableTabPage } from 'types/enums/DeliverableTabPage';
import { resetFilterAction } from '../../store/actions/filterActions';
import OrderCard from '../Orders/OrderCard/OrderCard';
import DeliverableActionBar from './DeliverableActionBar/DeliverableActionBar';
import DeliverableCreateReportByHand from './DeliverableCreateReportByHand/DeliverableCreateReportByHand';
import DeliverableDialogs from './DeliverableDialogs/DeliverableDialogs';
import DeliverableFilter from './DeliverableFilter/DeliverableFilter';
import DeliverableQuickActions from './DeliverableQuickActions/DeliverableQuickActions';
import DeliverableReports from './DeliverableReports/DeliverableReports';
import DeliverableTab from './DeliverableTab/DeliverableTab';
import DeliverableTable from './DeliverableTable/DeliverableTable';
import Drawer from './Drawer/Drawer';
import useDeliverableService from './hooks/useDeliverableService';
import { OrderType } from 'types/enums/OrderType';

type Params = {
  orderId: string;
};

const Deliverables: React.FC<RouteComponentProps> = () => {
  const { orderId } = useParams<Params>();
  const { makeCall } = useApi();
  const { handleDeliverableSearchParams, fetchOrder, fetchDeliverables } = useDeliverableService();
  const { dispatch } = useAppState();
  const { app, deliverable, filter, deliverableSheet } = useSelector((state) => state);
  const userRoles = useUserRoles();
  const { addCancelToken: ApiAddCancelToken, cancelRequests } = useCancelApi();

  const fetchFrequencies = useCallback(async () => {
    const frequencies = await makeCall(MissionApiService.fetchAllFrequencies(), 'Unable to retrieve frequencies');
    if (frequencies) {
      dispatch(loadFrequenciesAction(frequencies ?? []));
    }
  }, [dispatch, makeCall]);

  const fetchStatus = useCallback(async () => {
    const status = await makeCall(MissionApiService.fetchAllStatus(), 'Unable to retrieve status');
    if (status) {
      dispatch(loadStatusAction(status ?? []));
    }
  }, [dispatch, makeCall]);

  const fetchOrderScopes = useCallback(async () => {
    const orderScopes = await makeCall(
      OrderScopesApiService.getOrderScopes(Number(orderId) as number, { join: ['accountable', 'scope'] })
    );
    if (orderScopes) {
      dispatch(loadOrderScopesAction(orderScopes));
    }
  }, [dispatch, makeCall, orderId]);

  const prevSearchParams = useRef<SearchParams | null>(null);

  useEffect(() => {
    prevSearchParams.current = null;
  }, [deliverable.currentTab]);

  const fetchCatalogWorkunits = async () => {
    const { order } = deliverable;
    if (order && order?.catalog_id) {
      const res = await makeCall(
        WorkunitApiService.get(order.catalog_id, {
          size: -1,
          join: ['complexity'],
        }),
        'Unable to retrieve workunits in catalog'
      );
      if (res) {
        dispatch(loadWorkunitsAction(res.datas ?? []));
      }
    }
  };

  const fetchCustomers = async () => {
    if (app.customer && app.customer.id) {
      const users = await makeCall(
        UserApiService.getClientsOnCustomer(app.customer.id, { is_archived: 0, size: -1 }),
        'Unable to retrieve customers'
      );

      if (users) {
        dispatch(loadCustomersAction(users));
      }
    }
  };

  const getDeliverableSheets = useCallback(async () => {
    if (deliverable.order?.id) {
      if (deliverableSheet.currentReportTab === DeliverableReportTab.TECHNICAL) {
        const response = await makeCall(
          DeliverableSheetApiService.fetchDeliverableSheetInOrder(
            deliverable.order.id,
            deliverableSheet.pageSize,
            deliverableSheet.currentPage
          ),
          'Unable to fetch deliverables sheets '
        );
        dispatch(loadDeliverableSheetsAction(response));
      }
      if (deliverableSheet.currentReportTab === DeliverableReportTab.FINANCIAL) {
        const response = await makeCall(
          DeliverableSheetApiService.fetchFinancialReportsInOrder(
            deliverable.order.id,
            deliverableSheet.pageSize,
            deliverableSheet.currentPage
          ),
          'Unable to fetch deliverables sheets '
        );
        dispatch(loadDeliverableSheetsAction(response));
      }
    }
  }, [
    deliverable?.order?.id,
    deliverableSheet.currentReportTab,
    deliverableSheet.pageSize,
    deliverableSheet.currentPage,
    makeCall,
    dispatch,
  ]);

  const getFinancialReports = useCallback(async () => {
    if (deliverable.order?.id) {
      const response = await makeCall(
        orderApiService.getFinancialReports(deliverable.order.id),
        'Unable to fetch deliverables sheets '
      );
      dispatch(loadDeliverableSheetsAction(response));
    }
  }, [dispatch, makeCall, deliverable.order]);

  const fetchConsultants = async () => {
    if (app.customer && app.customer.id) {
      const users = await makeCall(UserApiService.fetchPossiblesConsultants(), 'Unable to retrieve consultants');

      if (users) {
        dispatch(loadConsultantsAction(users));
      }
    }
  };

  const fetchDeliveryManagers = useCallback(async () => {
    const users = await makeCall(
      UserApiService.fetchPossiblesDeliverableTeamLeaders(),
      'Unable to retrieve delivery managers'
    );

    if (users) {
      dispatch(loadDeliveryManagersAction(users));
    }
  }, [dispatch, makeCall]);

  useEffect(
    () => () => {
      dispatch(resetDeliverablesAction());
      prevSearchParams.current = null;
      dispatch(resetFilterAction());
      dispatch(selectDeliverablesAction([]));
      dispatch(cancelSelectByHand());
    },
    [dispatch]
  );

  useEffect(() => {
    if (orderId && !Number.isNaN(orderId)) {
      fetchOrderScopes();
    }
  }, [orderId, fetchOrderScopes]);

  useEffect(() => {
    Promise.all([fetchFrequencies(), fetchStatus(), fetchOrder(Number(orderId))]).catch((error) => {
      handleApiError(error);
    });
  }, [fetchFrequencies, fetchOrder, fetchStatus, orderId, app.roles]);

  useEffect(() => {
    // TODO update deliverable filter initial state to prevent double call (undefined/{})
    const delayDebounceFn = setTimeout(() => {
      handleDeliverableSearchParams();
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filter.selected,
    deliverable.currentTableType,
    deliverable.currentPage,
    deliverable.pageSize,
    deliverable.sortDeliverablesModel,
    deliverable.currentTab,
  ]);

  useEffect(() => {
    if (!userRoles.isCustomer && !userRoles.isSupportBu) {
      fetchCustomers();
    }
    if (!userRoles.isCustomer && !userRoles.isSupportBu && !userRoles.isConsultant) {
      fetchConsultants();
    }
    if (!userRoles.isCustomer && !userRoles.isSupportBu) {
      fetchDeliveryManagers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.customer]);

  function handleFetchDeliverables() {
    ApiAddCancelToken('cancelTokenFetchDeliverables');

    const delayDebounceFn = setTimeout(() => {
      if (deliverable.searchParams && filter.items.length) {
        fetchDeliverables(orderId, prevSearchParams);
      }
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
      if (app.cancelTokens.cancelTokenFetchDeliverables) {
        app.cancelTokens.cancelTokenFetchDeliverables.cancel();
      }
      cancelRequests('cancelTokenFetchDeliverables');
    };
  }
  useEffect(() => {
    handleFetchDeliverables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverable.searchParams, filter.items]);

  useEffect(() => {
    if (!userRoles.isCustomer && !userRoles.isSupportBu && !userRoles.isButl) {
      fetchCatalogWorkunits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliverable?.order?.catalog_id]);

  useEffect(() => {
    getDeliverableSheets();
    // getFinancialReports();
  }, [getDeliverableSheets]);

  const tabContent = () => (
    <>
      {/*  <DeliverableSwitchTableType /> */}
      <DeliverableActionBar />
      <DeliverableTable />
    </>
  );

  return (
    <div className="deliverables-container">
      <Layout name="Deliverables" className="deliverables-page" path={`/deliverables/${deliverable.order?.id}`}>
        {app.roles && (
          <div>
            {deliverable.order?.id && (
              <OrderCard
                order={{ ...deliverable.order, 'order-workunits': deliverable.deliverables ?? [] }}
                menu={false}
                stats={false}
                kpi={deliverable.order.order_type_id === OrderType.TIMEANDMATERIAL}
                showLink={false}
              />
            )}
            <TabContext value={deliverable.currentTab}>
              <DeliverableTab />
              <TabPanel value={DeliverableTabPage.TODO} sx={{ px: 0 }}>
                {tabContent()}
              </TabPanel>
              <TabPanel value={DeliverableTabPage.PRODUCTION} sx={{ px: 0 }}>
                {tabContent()}
              </TabPanel>
              <TabPanel value={DeliverableTabPage.REPORTS} sx={{ px: 0 }}>
                <DeliverableReports />
              </TabPanel>
            </TabContext>
            <Drawer />
            <DeliverableFilter />
            <DeliverableDialogs />
            <DeliverableQuickActions />
            <DeliverableCreateReportByHand />
          </div>
        )}
      </Layout>
    </div>
  );
};

export default Deliverables;
