import { Typography, Tooltip } from '@mui/material';
import React from 'react';
import HelpMenuListVideos from 'types/entities/HelpMenuVideos';

const CategoriesCell = React.memo((row: HelpMenuListVideos) => {
  const categoryNames =
    row.categories && row?.categories?.length > 0 ? row.categories?.map((category) => category.name).join(', ') : '';

  return (
    <Tooltip arrow title={categoryNames}>
      <Typography noWrap variant="body2">
        {categoryNames}
      </Typography>
    </Tooltip>
  );
});

export default CategoriesCell;
