import ResourceAPI, { Resource, SearchParams } from 'api/ResourceAPI';
import { AxiosResponse } from 'axios';
import HelpMenuListVideos from 'types/entities/HelpMenuVideos';
import Category from 'types/entities/VideoCategory';
import HelpMenuUserGuide from 'types/entities/HelpMenuUserGuide';

export default class HelpMenuApiService {
  public static instance: HelpMenuApiService;

  public static baseUrl = 'helpmenu/list' as Resource;

  public static urlCategory = 'helpmenu/createcategories' as Resource;

  public static getCategory = 'helpmenu/categories' as Resource;

  public static deleteVideoUrl = 'helpmenu/video/delete' as Resource;

  public static createVideoUrl = 'helpmenu/create' as Resource;

  public static createVideos3ThumbnailUrl = 'helpmenu/videos/thumbnail' as Resource;

  public static updateVideoUrl = 'helpmenu/video' as Resource;

  public static videothumbnailURL = 'videos/thumbnail/list' as Resource;

  public static createGuideUrl = 'helpmenu/user-guide' as Resource;

  public static guideUrl = 'helpmenu/guide' as Resource;

  public static baseAdminGuideUrl = 'helpmenu/user-guide-list' as Resource;

  public static baseGuideListUrl = 'helpmenu/user-guide-details/';

  public static deleteGuideUrl = 'helpmenu/user-guide/delete' as Resource;

  public static guidePdfUrl = 'helpmenu/user-guide' as Resource;

  public static updateGuideUrl = 'helpmenu/user-guide' as Resource;

  public static get Instance() {
    // eslint-disable-next-line no-return-assign
    return HelpMenuApiService.instance || (HelpMenuApiService.instance = new HelpMenuApiService());
  }

  public static async createCategory(body: Partial<Category>): Promise<AxiosResponse<{ category: Category }> | null> {
    try {
      return await ResourceAPI.post(`${this.urlCategory}`, body);
    } catch (error: unknown) {
      return null;
    }
  }

  public static async getHelpMenuVideos(
    size: number,
    page: number,
    // sorted: string,
    search: SearchParams
  ): Promise<{ totalItems: number; datas: HelpMenuListVideos[]; totalPages: number }> {
    try {
      // eslint-disable-next-line max-len
      const params = {
        // sorted,
        size,
        page,
        ...search,
      };

      const {
        data: { totalItems, datas, totalPages },
      } = await ResourceAPI.get(this.baseUrl, params);
      return { totalItems, datas, totalPages };
    } catch (error) {
      return { totalItems: 0, datas: [], totalPages: 0 };
    }
  }

  public static async fetchthumbnail() {
    try {
      const { data } = await ResourceAPI.get(this.videothumbnailURL);
      return data;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async fetchVideo(videoId: number) {
    try {
      const res = await ResourceAPI.getById('helpmenu', videoId, 'video');
      return res.data;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async getCatelogue(): Promise<{ datas: Category[] }> {
    try {
      // eslint-disable-next-line max-len
      const {
        data: { categories },
      } = await ResourceAPI.get(this.getCategory);
      return categories;
    } catch (error) {
      return { datas: [] };
    }
  }

  public static async deleteVideo(id: number) {
    try {
      const {
        data: { data },
      } = await ResourceAPI.delete(this.deleteVideoUrl, id);
      return data;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async createVideo(data: any) {
    try {
      const res = await ResourceAPI.post(this.createVideoUrl, data);

      return res.data;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async videoUpload(id: number, params?: SearchParams) {
    try {
      const { data } = await ResourceAPI.getById(this.updateVideoUrl, id, `upload`, params);
      return data;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async saveContentVideoS3(resource: any, data: any) {
    try {
      const res = await ResourceAPI.put(this.createVideos3ThumbnailUrl, resource, data, 'upload');
      return res;
    } catch (error) {
      return undefined;
    }
  }

  public static async updateVideo(id: number, data: any) {
    try {
      const res = await ResourceAPI.put(this.updateVideoUrl, id, data, '');
      return res.data;
    } catch (error: unknown) {
      return null;
    }
  }

  // USER GUIDE

  public static async createGuide(data: any) {
    try {
      const res = await ResourceAPI.post(this.createGuideUrl, data);
      return res.data;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async saveContentGuideS3(resource: any, data: any) {
    try {
      const res = await ResourceAPI.put(this.guideUrl, resource, data, 'upload');
      return res;
    } catch (error) {
      return undefined;
    }
  }

  public static async deleteGuide(id: number) {
    try {
      const res = await ResourceAPI.delete(this.deleteGuideUrl, id);
      return res;
    } catch (error: unknown) {
      return null;
    }
  }

  public static async fetchGuidepdf(guideId: number) {
    try {
      const { data } = await ResourceAPI.getById(this.guidePdfUrl, guideId);
      return data;
    } catch (error: unknown) {
      return [];
    }
  }

  public static async getHelpMenuGuides(
    size: number,
    page: number,
    // sorted: string,
    search: SearchParams
  ): Promise<{ totalItems: number; datas: HelpMenuUserGuide[]; totalPages: number }> {
    try {
      // eslint-disable-next-line max-len
      const params = {
        // sorted,
        size,
        page,
        ...search,
      };

      const {
        data: { totalItems, datas, totalPages },
      } = await ResourceAPI.get(this.baseAdminGuideUrl, params);
      return { totalItems, datas, totalPages };
    } catch (error) {
      return { totalItems: 0, datas: [], totalPages: 0 };
    }
  }

  public static async getHelpMenuUserGuides(
    size: number,
    page: number,
    // sorted: string,
    search: SearchParams,
    roleName: string
  ): Promise<{ totalItems: number; datas: HelpMenuUserGuide[]; totalPages: number }> {
    try {
      // eslint-disable-next-line max-len
      const params = {
        // sorted,
        // size,
        // page,
        // ...search,
      };

      const {
        data: { count, rows },
      } = await ResourceAPI.get((this.baseGuideListUrl + roleName) as Resource, params);
      return { totalItems: count, datas: rows, totalPages: 0 };
    } catch (error) {
      return { totalItems: 0, datas: [], totalPages: 0 };
    }
  }

  public static async updateGuide(id: number, data: any) {
    try {
      const res = await ResourceAPI.patch(this.updateGuideUrl, data, id);
      return res.data;
    } catch (error: unknown) {
      return null;
    }
  }
}
