import { Typography, Tooltip } from '@mui/material';
import React from 'react';
import HelpMenuUserGuide from 'types/entities/HelpMenuUserGuide';

const AccessByCell = React.memo((row: HelpMenuUserGuide) => {
  const accessby = row.roles && row?.roles?.length > 0 ? row.roles?.map((item) => item.name).join(', ') : '';

  return (
    <Tooltip arrow title={accessby}>
      <Typography noWrap variant="body2">
        {accessby}
      </Typography>
    </Tooltip>
  );
});

export default AccessByCell;
