import React, { useCallback } from 'react';
import AutoStories from '@mui/icons-material/AutoStories';
import { Card, CardHeader, CardContent, Typography, Grid, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store/hooks';
import Loader from 'components/Loader';
import EmptyState from 'components/EmptyState';
import HelpMenuUserGuide from 'types/entities/HelpMenuUserGuide';
import useHelpMenuService from 'features/HelpMenu/hooks/useHelpMenuService';

interface UserGuideCard {
  userGuides: HelpMenuUserGuide[] | null;
}

const UserGuideCard: React.FC<UserGuideCard> = ({ userGuides }) => {
  const [t] = useTranslation();
  const loadings = useSelector((state) => state.loadings);
  const { getGuidePdf } = useHelpMenuService();

  const getGuide = useCallback(async (id) => {
    const data = await getGuidePdf(id);
    if (data?.length > 0) {
      window.open(data, '_blank');
    }
  }, []);

  return loadings?.videothumbnail ? (
    <Loader className="loader_container_thumbnail" />
  ) : (
    <>
      {userGuides && userGuides?.length === 0 && (
        <Grid item xs={12} xl="auto">
          <EmptyState title={t('no_userGuide_found')} />
        </Grid>
      )}
      <Grid container flexWrap="wrap" gap={2}>
        {userGuides?.map((guide, index) => (
          <Card sx={{ maxWidth: 345, cursor: 'pointer' }} onClick={() => getGuide(guide?.id)}>
            <CardHeader
              avatar={
                <IconButton>
                  <AutoStories fontSize="large" sx={{ color: '#0f5ee9' }} />
                </IconButton>
              }
            />
            <CardContent>
              <Typography variant="h6">{guide.title}</Typography>
              <Tooltip
                title={guide?.description}
                componentsProps={{
                  tooltip: {
                    sx: {
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
                    },
                  },
                }}
                arrow
              >
                <Typography
                  variant="body2"
                  sx={{
                    height: '48px', // Same fixed height for two lines
                    display: '-webkit-box',
                    boxOrient: 'vertical',
                    lineClamp: 2,
                    wordBreak: 'break-all',
                    overflow: 'hidden',
                    minWidth: '310px',
                  }}
                >
                  {guide?.description.length <= 80 ? guide?.description : `${guide?.description?.slice(0, 80)}...`}
                </Typography>
              </Tooltip>
            </CardContent>
          </Card>
        ))}
      </Grid>
    </>
  );
};

export default UserGuideCard;
