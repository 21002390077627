import { GridRowId } from '@mui/x-data-grid-pro';
import { RootActions } from 'store/redux';
import { HelpMenuActions } from '../actions/helpmenuActions';
import { helpmenuState, HelpMenuState } from '../states/helpMenuState';
import HelpMenuListVideos from 'types/entities/HelpMenuVideos';

const helpmenuReducer = (state = helpmenuState, action: RootActions): HelpMenuState => {
  switch (action.type) {
    case HelpMenuActions.CALL_HELPMENU_FUNCTION:
      return {
        ...state,
        helpMenuApiFlag: !state.helpMenuApiFlag,
      };
    case HelpMenuActions.LOAD_HELPMENU:
      return {
        ...state,
        helpMenu: action.payload,
      };
    case HelpMenuActions.CHANGE_CURRENT_TAB_HELPMENU:
      return {
        ...state,
        currentTab: action.payload,
        currentTableType: action.payload,
      };
    case HelpMenuActions.SORT_HELP_MENU_VIDEOS:
      return {
        ...state,
        sortHelpMenuModel: action.payload,
      };
    case HelpMenuActions.HELPMENU_VIDEOS:
      return {
        ...state,
        totalItems: action.payload.totalItems,
        datas: action.payload.datas,
        totalPages: action.payload.totalPages,
      };
    case HelpMenuActions.GET_CATALOGUE_LIST:
      return {
        ...state,
        categories: action.payload.datas,
      };
    case HelpMenuActions.SELECT_VIDEO:
      return {
        ...state,
        activeVideoModel: action.payload ? [action.payload] : [],
      };
    case HelpMenuActions.SELECT_ALL_VIDEOS:
      return {
        ...state,
        selectedAllVideos: action.payload,
      };

    case HelpMenuActions.SELECT_VIDEOS:
      return {
        ...state,
        selectedVideoModel: action.payload,
      };

    case HelpMenuActions.UPDATE_VIDEO_ROW_MODEL:
      return {
        ...state,
        updateSelectedVideoModel: action.payload,
      };

    case HelpMenuActions.SELECT_VIDEO_MODEL_FLAG:
      return {
        ...state,
        selectVideoModelFlag: action.payload,
      };

    case HelpMenuActions.DELETE_VIDEO:
      return {
        ...state,
        totalItems: state.totalItems - 1,
        datas: state?.datas?.filter((video: HelpMenuListVideos) => video.id !== action.payload) ?? [],
        selectedVideoModel: state.selectedVideoModel.filter((id: GridRowId) => Number(id) !== action.payload),
      };

    case HelpMenuActions.CHANGE_SELECTED_VIDEOS_COUNT:
      return {
        ...state,
        selectedVideoCount: action.payload,
      };
    case HelpMenuActions.EXCLUDE_VIDEOS_COUNT:
      return {
        ...state,
        excludedVideos: action.payload,
      };

    // USER GUIDE
    case HelpMenuActions.HELPMENU_GUIDES:
      return {
        ...state,
        totalItems: action.payload.totalItems,
        guideDatas: action.payload.datas,
        totalPages: action.payload.totalPages,
      };
    case HelpMenuActions.SELECT_GUIDE_MODEL_FLAG:
      return {
        ...state,
        selectGuideModelFlag: action.payload,
      };
    case HelpMenuActions.GET_GUIDE_LIST:
      return {
        ...state,
        roles: action.payload.datas,
      };
    case HelpMenuActions.UPDATE_GUIDE_ROW_MODEL:
      return {
        ...state,
        updateSelectedGuideModel: action.payload,
      };
    case HelpMenuActions.SELECT_GUIDE:
      return {
        ...state,
        activeGuideModel: action.payload ? [action.payload] : [],
      };
    case HelpMenuActions.SELECT_GUIDES:
      return {
        ...state,
        selectedGuideModel: action.payload,
      };
    case HelpMenuActions.CHANGE_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case HelpMenuActions.CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case HelpMenuActions.CHANGE_SEARCH_PARAMS_USERGUIDE:
      return {
        ...state,
        searchParams: action.payload,
      };
    default:
      return state;
  }
};

export default helpmenuReducer;
