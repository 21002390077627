import { Typography } from '@mui/material';
import React from 'react';
import OrderWorkunit from 'types/entities/OrderWorkunit';

const OrderworkunitsIDCell = React.memo((row: OrderWorkunit) => (
  <Typography noWrap variant="body2">
    {row.id}
  </Typography>
));

export default OrderworkunitsIDCell;
