import { PERMISSIONS } from 'helpers/permissions';
import useUserPermissions, { hasPermissions } from 'hooks/useUserPermissions';
import { useSelector } from 'store/hooks';

/**
 * React hook that defines rights specific to the Catalog feature
 */
export default function useHelpMenuRights() {
  const userPermissions = useUserPermissions();
  const selectAll = useSelector((state) => state.helpMenu?.selectedAllVideos);
  const selectAllGuide = useSelector((state) => state.helpMenu?.selectedAllGuides);

  const rights = {
    canEdit: false,
    canDelete: false,
    canCreate: false,
  };

  rights.canCreate = hasPermissions([PERMISSIONS.CREATE_VIDEOS], userPermissions);
  rights.canEdit = hasPermissions([PERMISSIONS.EDIT_VIDEOS], userPermissions) && !selectAll;
  rights.canDelete = hasPermissions([PERMISSIONS.DELETE_VIDEOS], userPermissions);
  rights.canCreate = hasPermissions([PERMISSIONS.CREATE_USERGUIDES], userPermissions);
  rights.canEdit = hasPermissions([PERMISSIONS.EDIT_USERGUIDES], userPermissions) && !selectAllGuide;
  rights.canDelete = hasPermissions([PERMISSIONS.DELETE_USERGUIDES], userPermissions);

  return rights;
}
