import { useCallback, useEffect } from 'react';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import { setSnackbarAction } from 'store/actions/snackbarActions';
import { useAppState } from 'store/Provider';
import useCancelApi from './useCancelApi';

/**
 * React hook that defines the general behavior of all api calls
 */
export default function useApi() {
  const { initCancelToken } = useCancelApi();
  const { dispatch } = useAppState();

  useEffect(() => {
    initCancelToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const makeCall = useCallback(
    async (apiCall: Promise<any>, errorMessage?: string, loading = 'api'): Promise<typeof apiCall | void> => {
      if (loading) dispatch(addLoadingAction(loading));
      try {
        const res = await apiCall;

        if (!res && res !== undefined) throw Error(errorMessage);
        return res;
      } catch (e: any) {
        console.log('e: ', e);
        const result = (e as Error).message;
        const detailedMessage = e.response?.data?.message;
        console.log(`result: ${result}`);
        console.log(`detailedMessage: ${detailedMessage}`);
        const messageToDisplay = detailedMessage || result || errorMessage || 'Error';
        if (result) {
          if (e.response && e.response.status === 422) {
            dispatch(
              setSnackbarAction({
                message: `${e.response.data.message}`,
                open: true,
                severity: 'error',
                duration: 5000,
              })
            );
          } else {
            dispatch(
              setSnackbarAction({
                message: e.message || errorMessage || 'Error',
                open: true,
                severity: 'error',
                duration: 5000,
              })
            );
          }
        } else {
          dispatch(
            setSnackbarAction({
              message: e.message || errorMessage || 'Error',
              open: true,
              severity: 'error',
              duration: 5000,
            })
          );
        }
        // return false;
        return { error: true, message: messageToDisplay };
      } finally {
        if (loading) dispatch(removeLoadingAction(loading));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { makeCall };
}
