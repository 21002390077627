import { Typography, CardMedia } from '@mui/material';
import React, { useCallback } from 'react';
import { useAppState } from 'store/Provider';
import HelpMenuListVideos from 'types/entities/HelpMenuVideos';
import useHelpMenuService from 'features/HelpMenu/hooks/useHelpMenuService';

const VideoCell = React.memo((row: HelpMenuListVideos) => {
  const { fetchVideo } = useHelpMenuService();

  const getVideo = useCallback(async (videoId: number) => {
    const data = await fetchVideo(videoId);
    window.open(data, '_blank');
  }, []);

  return (
    <Typography noWrap variant="body2" sx={{ cursor: 'pointer' }}>
      <CardMedia
        height={60}
        width={250}
        component="img"
        src={row.presignedUrl || ''}
        alt={row.title}
        onClick={() => getVideo(row.id)}
      />
    </Typography>
  );
});

export default VideoCell;
