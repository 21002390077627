import React from 'react';
import UserGuideCard from '../../../components/UserGuide/UserGuideCard';
import { useSelector } from 'store/hooks';

const HelpMenuUserGuide: React.FC = () => {
  const state = useSelector((state) => state.helpMenu);
  return <UserGuideCard userGuides={state?.guideDatas} />;
};

export default HelpMenuUserGuide;
