import MoreVert from '@mui/icons-material/MoreVert';
import { CircularProgress, IconButton, ListItemIcon, ListItemText, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { DeleteIcon, EditIcon } from 'components/CustomIcons';
import useHelpMenuRights from 'hooks/rights/useHelpMenuRights';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { selectGuideModelFlagAction, selectGuideAction, updateGuideRowAction } from 'store/actions/helpmenuActions';
import { openDialogAction } from 'store/actions/dialogsActions';
import { useSelector } from 'store/hooks';
import { useAppState } from 'store/Provider';
import { addLoadingAction, removeLoadingAction } from 'store/actions/loadingsActions';
import HelpMenuUserGuide from 'types/entities/HelpMenuUserGuide';

const UserGuideActionsCell = React.memo((row: HelpMenuUserGuide) => {
  const [t] = useTranslation();
  const { dispatch } = useAppState();
  const loadings = useSelector((state) => state.loadings);
  const helpmenuRights = useHelpMenuRights();

  const [anchorMenuActionEl, setAnchorMenuActionEl] = React.useState<null | HTMLElement>(null);

  const handleMenuActionOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenuActionEl(event.currentTarget);
  };

  const handleMenuActionClose = () => {
    setAnchorMenuActionEl(null);
  };

  const handleActions = async (item: any) => {
    dispatch(addLoadingAction(`action${item.label}`));
    dispatch(selectGuideAction(row.id));
    await item.action();
    dispatch(removeLoadingAction(`action${item.label}`));
    handleMenuActionClose();
  };

  const editAction = useCallback(() => {
    dispatch(selectGuideModelFlagAction(true));
    dispatch(updateGuideRowAction(row));
    // dispatch(openDrawerAction({ name: 'deliverableEdit', data: [row.id] }));
  }, [dispatch, row]);

  const deleteAction = useCallback(() => {
    dispatch(openDialogAction({ name: 'guideDelete' }));
  }, [dispatch]);

  const menuItems = useMemo(
    () => [
      {
        action: editAction,
        permission: helpmenuRights.canEdit,
        label: 'edit',
        icon: <EditIcon sx={{ color: 'text.primary' }} fontSize="small" />,
      },

      {
        action: deleteAction,
        label: 'delete',
        icon: <DeleteIcon color="error" fontSize="small" />,
        isError: true,
        permission: helpmenuRights.canDelete,
      },
    ],
    [deleteAction, editAction]
  );

  const menuItemsAvailables = useMemo(() => menuItems.filter((item) => item.permission !== false), [menuItems]);
  return (
    <>
      <IconButton onClick={handleMenuActionOpen} size="large" disabled={!menuItemsAvailables.length}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorMenuActionEl}
        open={Boolean(anchorMenuActionEl)}
        onClose={handleMenuActionClose}
      >
        {menuItemsAvailables.map((item) => (
          <MenuItem key={item.label} onClick={() => handleActions(item)} disabled={!!loadings[`action${item.label}`]}>
            <ListItemIcon>
              {loadings[`action${item.label}`] ? <CircularProgress size="1.5rem" color="inherit" /> : item.icon}
            </ListItemIcon>
            <ListItemText sx={{ color: !item.isError ? '' : 'error.main' }}>{t(item.label)}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

export default UserGuideActionsCell;
